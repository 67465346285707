import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import {
  FilterData,
  FilterFilterFormData,
  FilterVm,
} from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-filter-parameters',
  templateUrl: './filter-parameters.component.html',
  styleUrls: ['./filter-parameters.component.scss'],
})
export class FilterParametersComponent {
  @ViewChild('template', { static: true }) template: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];
  @Input() filterForm: FormGroup;

  filterOutputArray: FilterVm[];

  filterBySearch = '';

  loadEqueationDataArr: any[] = [];

  stringDataDropDown: LookUpElement[] = [
    {
      id: 'Equal',
      name: 'Is Equal To',
    },
    {
      id: 'NotEqual',
      name: 'Is NOT Equal To',
    },
    {
      id: 'Contains',
      name: 'Contains',
    },
    {
      id: 'StartsWith',
      name: 'Starts with',
    },
    {
      id: 'EndsWith',
      name: 'Ends with',
    },
    {
      id: 'IsEmpty',
      name: 'Is Empty',
    },
    {
      id: 'IsNotEmpty',
      name: 'Is Not Empty',
    },
  ];
  lookupDataDropDown: LookUpElement[] = [
    {
      id: 'Equal',
      name: 'Is Equal To',
    },
    {
      id: 'NotEqual',
      name: 'Is NOT Equal To',
    },
    {
      id: 'Contains',
      name: 'Contains',
    },
    {
      id: 'StartsWith',
      name: 'Starts with',
    },
    {
      id: 'EndsWith',
      name: 'Ends with',
    },
    {
      id: 'IsEmpty',
      name: 'Is Empty',
    },
    {
      id: 'IsNotEmpty',
      name: 'Is Not Empty',
    },
    {
      id: 'IsAnyOf',
      name: 'Is Any Of',
    },
    {
      id: 'IsAllOf',
      name: 'Is All Of',
    },

    {
      id: 'IsNoneOf',
      name: 'Is None Of',
    },
  ];
  filterClauseDropDown: LookUpElement[] = [
    {
      id: 'AND',
      name: 'AND',
    },
    {
      id: 'OR',
      name: 'OR',
    },
  ];

  numberDataDropDown: LookUpElement[] = [
    {
      id: 'Equal',
      name: 'Is Equal To',
    },
    {
      id: 'NotEqual',
      name: 'Is NOT Equal To',
    },
    {
      id: 'GreaterThan',
      name: 'Greater Than',
    },
    {
      id: 'LessThan',
      name: 'Less Than',
    },
    {
      id: 'GreaterThanOrEqual',
      name: 'Greater Than Or Equal',
    },
    {
      id: 'LessThanOrEqual',
      name: 'Less Than Or Equal',
    },
    {
      id: 'Between',
      name: 'Between',
    },
  ];

  dateDataDropDown: LookUpElement[] = [
    {
      id: 'Equal',
      name: 'Is Equal To',
    },
    {
      id: 'NotEqual',
      name: 'Is Not Equal To',
    },
    {
      id: 'GreaterThan',
      name: 'Greater Than',
    },
    {
      id: 'LessThan',
      name: 'Less Than',
    },
    {
      id: 'GreaterThanOrEqual',
      name: 'Greater Than Or Equal',
    },
    {
      id: 'LessThanOrEqual',
      name: 'Less Than Or Equal',
    },
    {
      id: 'Between',
      name: 'Between',
    },
    {
      id: 'IsAnyOf',
      name: 'Is Any Of',
    },
    // {
    //   id: "IsAllOf",
    //   name: "Is All Of"
    // },

    // {
    //   id: "IsNoneOf",
    //   name: "Is None Of"
    // }
  ];

  allowSecondValue: string[] = [];
  allowLookup: string[] = ['IsAnyOf', 'IsAllOf', 'IsNoneOf'];
  allowSingleDate: string[] = ['Between', 'IsAllOf', 'IsAnyOf'];
  joinValue: string = '';
  /**
   *
   */
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private helperService: HelpersService,
    private dateAdapter: DateAdapter<Date>,
    private filterSharedService: FilterSharedService
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.filterDataSubject = filterSharedService
      .getsubjectFilterParameterOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal();
        }
      });

    this.filterDataSubject = filterSharedService
      .getsubjectFilterData()
      .pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;

          this.coloumnsData = filterData?.ColumnsData.filter(
            (data) => data.allowFilter
          );
        }
      });
    this.filterDataSubject = filterSharedService
      .getsubjectFilterReset()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {
          this.resetFilter(true);
        }
      });
  }
  ngAfterViewInit(): void {}
  ngOnInit(): void {
    this.allowSecondValue = ['Between'];
    if (this.getFilterValues?.length == 0) {
      this.addNewFilterValue();
    }
    this.getValueChanges();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg',
    });
  }

  // Method to add a new FormArray dynamically
  addNewFiltersArray() {
    this.filterForm?.addControl('Filters', this.fb.array([]));
  }

  // Method to get the Filters FormArray
  get getFilterValues(): FormArray {
    return this.filterForm?.controls['Filters'] as FormArray;
  }

  addNewFilterValue() {
    this.addNewFiltersArray();
    const filtersArray = this.getFilterValues;

    if (filtersArray) {
      filtersArray.push(this.newFilterValue());
    }

    if (
      this.getFilterValues?.controls &&
      this.getFilterValues.controls?.length > 0
    ) {
      var filterbyValues = this.getFilterValues.controls.map(
        (element) => element?.value?.FilterBy
      );

      var filteredColsArray = this.coloumnsData.filter(
        (x) => !filterbyValues.includes(x?.data)
      );
      this.getFilterValues.controls[filtersArray.length - 1].patchValue({
        ColoumnsArray: filteredColsArray,
      });
    }
  }

  removeFilterValue(i: number) {
    this.getFilterValues.removeAt(i);

    if (i == 0) {
      this.addNewFilterValue();
    }
  }

  // Helper method to create a new filter value (FormGroup)
  newFilterValue(): FormGroup {
    return this.fb.group({
      FilterBy: [''], // Make sure the FormControl name is 'FilterBy'
      Translate: [''],
      FilterClause: ['AND'], // Make sure the FormControl name is 'FilterBy'
      Condition: [''], // Make sure the FormControl name is 'FilterBy'
      Value: [], // Make sure the FormControl name is 'FilterBy'
      SecondaryValue: [], // Make sure the FormControl name is 'FilterBy'
      ConditionArray: [this.stringDataDropDown],
      ColoumnsArray: [this.coloumnsData],
      FilterClauseArray: [this.filterClauseDropDown],
      DataType: ['STRING'],
      IsActive: true,
      Dates: this.fb.array([]),

      LookupArray: [[]],
      LookupValue: [[]],
      ConditionText: [''],
    });
  }

  conditionChange(event, i) {
    this.getFilterValues?.controls[i]?.patchValue({
      Value: '',
      SecondaryValue: '',
      LookupValue: [],
    });

    const datesArray = this.getFilterValues.controls[i].get(
      'Dates'
    ) as FormArray;

    datesArray.clear();

    this.getFilterValues.controls[i]
      .get('ConditionArray')
      .value.forEach((element) => {
        if (event.value == element.id) {
          this.getFilterValues.controls[i].patchValue({
            ConditionText: element.name,
          });
        }
      });

    var data: any = this.coloumnsData.filter(
      (res) =>
        res.data == this.getFilterValues.controls[i].get('FilterBy').value
    );

    if (this.getFilterValues.controls[i].get('DataType').value == 'LOOKUP') {
      this.getFilterValues.controls[i].patchValue({
        LookupArray: data[0]?.lookup,
      });
    }
    // else {
    //   this.getFilterValues.controls[i].patchValue({
    //     LookupValue: [],
    //   });
    // }
  }

  filterByChange(event, i) {
    var val = event.value;

    var data = this.coloumnsData.filter((res) => res.data == val);

    if (data.length > 0) {
      // const valueField =  this.getFilterValues.controls[i].get('Value');

      if (data[0]?.dataType == 'NUMBER') {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.numberDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: '',
        });
        // valueField.setValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
      } else if (data[0].dataType == 'LOOKUP') {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.lookupDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: '',
        });
      } else if (data[0].dataType == 'DATE') {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.dateDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: '',
        });
      } else {
        this.getFilterValues.controls[i].patchValue({
          Translate: data[0].translate,
          ConditionArray: this.stringDataDropDown,
          DataType: data[0]?.dataType,
          Condition: '',
          Value: '',
        });
        // valueField.clearValidators();
      }

      this.conditionChange({ value: val }, i);
    }
  }

  onKeyUp(event: any, i: number) {
    this.checkMinValue(i);

    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;
    var filterVal = this.getFilterValues.controls[i].get('FilterBy');

    // Remove non-numeric characters
    const numericValue = currentValue.replace(/[^0-9]/g, '');

    var data = this.coloumnsData.filter((res) => res.data == numericValue);

    if (data.length > 0) {
      if (data[0]?.dataType == 'NUMBER') {
        inputElement.value = numericValue;

        this.getFilterValues.controls[i].get('Value').setValue(numericValue);
      }
    }
  }

  resetFilter(isClearFromMainFilterMenu = false) {
    this.getFilterValues?.clear();
    this.addNewFilterValue();
    if (!isClearFromMainFilterMenu) {
      this.filterSharedService.setsubjectApplyFilter(true);
    }
  }

  get dates(): FormArray {
    return this.filterForm.get('dates') as FormArray;
  }

  ngOnDestroy() {
    this.filterSharedService.setsubjectFilterParameterOpenModal(null);
    this.destroy.next(null);
    this.modalRef?.hide();
  }

  addDate(event: any, i: number, isInitial: boolean) {
    const date = this.helperService.setDateFormatted(event.value);
    // var clause = this.getFilterValues.controls[i].get('Dates')?.value;
    // var datesArray = this.getFilterValues.controls[i].get('Dates') as FormArray;

    if (isInitial) {
      this.getFilterValues?.controls[i]?.get('Value')?.patchValue(date);
    } else {
      this.getFilterValues?.controls[i]
        ?.get('SecondaryValue')
        ?.patchValue(date);
    }

    if (
      !this.allowSingleDate.includes(
        this.getFilterValues.controls[i].get('Condition')?.value
      )
    ) {
      const datesArray = this.getFilterValues.controls[i].get(
        'Dates'
      ) as FormArray;

      // Check if there are more than 1 elements in the FormArray
      if (datesArray.length > 1) {
        // Remove all elements except the first one (at index 0)
        while (datesArray.length > 1) {
          datesArray.removeAt(1);
        }
      }
      this.getFilterValues.controls[i].patchValue({
        Value: date.toString(),
      });
    } else {
      if (
        !this.getFilterValues.controls[i].get('Dates')?.value.includes(date)
      ) {
        (this.getFilterValues.controls[i].get('Dates') as FormArray).push(
          this.fb.control(date)
        );
      }
      if (
        this.getFilterValues.controls[i].get('Condition').value == 'IsAnyOf' &&
        this.getFilterValues.controls[i].get('DataType').value == 'DATE'
      ) {
        this.getFilterValues.controls[i].patchValue({
          LookupValue: this.getFilterValues.controls[i].get('Dates')?.value,
        });

        // this.getFilterValues.controls[i].patchValue({
        //   Value: '',
        // });
      }
    }
  }

  removeDate(index: number, i) {
    const datesArray = this.getFilterValues.controls[i].get(
      'Dates'
    ) as FormArray;

    datesArray.removeAt(index);
    
    this.getFilterValues.controls[i].patchValue({
      LookupValue: this.getFilterValues.controls[i].get('Dates')?.value,
    });

    if (datesArray?.value.length == 0) {
      this.getFilterValues.controls[i].patchValue({
        Value: '',
      });
    }
  }

  getValueChanges() {
    this.filterForm.controls.Filters?.valueChanges.subscribe(
      (searchformValues) => {
        this.filterOutputArray = [];

        var filters: FilterFilterFormData[] = searchformValues;

        filters.forEach((filterVal) => {
          if (
            filterVal.IsActive &&
            filterVal.FilterBy != '' &&
            filterVal.Condition != ''
          ) {
            this.filterOutputArray.push({
              condition: filterVal.Condition,
              filterBy: filterVal.FilterBy,
              filterClause: filterVal.FilterClause,
              value: filterVal.Value,
              dataType: filterVal.DataType,
              secondaryValue: filterVal.SecondaryValue,
              lookupValueList: filterVal.LookupValue,
            });
          }
        });

        this.filterForm.patchValue({
          FilterValues: this.filterOutputArray,
        });
      }
    );
  }

  getlookupvalue(i) {
    var lookupValue =
      this.getFilterValues?.controls[i]?.get('LookupValue').value;
    var lookupArray =
      this.getFilterValues?.controls[i]?.get('LookupArray').value;

    this.joinValue = lookupArray
      .filter((c) => lookupValue.includes(c.id))
      .map((c) => c.name)
      .join(', ');
  }

  applyFilter() {
    this.filterSharedService.setsubjectApplyFilter(true);
  }

  isLastFilterValid(): boolean {
    const lastIndex = this.getFilterValues.controls.length - 1;
    return this.isFilterValid(lastIndex);
  }

  isFilterValid(i: number): boolean {
    const filterGroup = this.getFilterValues.controls[i];
    const isActive = filterGroup.get('IsActive').value;
    const filterBy = filterGroup.get('FilterBy').value;
    const condition = filterGroup.get('Condition').value;
    return isActive && filterBy && condition;
  }

  formatDatesWithComma(i: number): string {
    const datesArray =
      this.getFilterValues.controls[i]?.get('Dates')?.value || [];
    return datesArray
      ?.map((date) => moment(date)?.format('YYYY-MM-DD'))
      ?.join(', ');
  }

  checkMinValue(i: number): void {
    const condition = this.getFilterValues.controls[i].get('Condition').value;
    const dataType = this.getFilterValues.controls[i].get('DataType').value;

    const value = parseFloat(
      this.getFilterValues.controls[i]?.get('Value')?.value
    );
    const secondaryValue = parseFloat(
      this.getFilterValues.controls[i]?.get('SecondaryValue')?.value
    );

    if (dataType === 'NUMBER') {
      // Check if 'Value' is less than 0
      if (!isNaN(value) && value < 0) {
        this.getFilterValues.controls[i]
          .get('Value')
          ?.setErrors({ minValue: true });
      } else {
        this.getFilterValues.controls[i].get('Value')?.setErrors(null);
      }
    }

    if (condition === 'Between' && dataType === 'NUMBER') {
      // Check if 'SecondaryValue' is less than 0
      if (!isNaN(secondaryValue) && secondaryValue < 0) {
        this.getFilterValues.controls[i]
          .get('SecondaryValue')
          ?.setErrors({ minValue: true });
      } else {
        this.getFilterValues.controls[i].get('SecondaryValue')?.setErrors(null);
      }

      // Check if 'SecondaryValue' is less than 'Value'
      if (!isNaN(value) && !isNaN(secondaryValue) && secondaryValue < value) {
        this.getFilterValues.controls[i]
          .get('SecondaryValue')
          ?.setErrors({ min: true });
      } else {
        this.getFilterValues.controls[i].get('SecondaryValue')?.setErrors(null);
      }
    }
    this.cdr.detectChanges();
  }
}
