<ng-template #template>
  <div class="row container">
    <div class="col-md-6">
      <h5 class="modal-title pull-left"><b>Choose Lead</b></h5>
    </div>
    <div class="col-md-6 d-flex flex-row-reverse mt-4">
        <button mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        (click)="createLead()"
        >
            New Lead<mat-icon>add</mat-icon>
        </button>
    </div>
  </div>
  <div class="modal-body">
    <app-loader *ngIf="isLoading"></app-loader>
      <div class="row mt-2">
          <div class="col-md-12 table-container">
            <table mat-table [dataSource]="dataSource" class="fleet-table">
            <mat-radio-group [(ngModel)]="selectedLeadId" (change)="onRadioButtonChange($event)">
              <ng-container matColumnDef="Select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <mat-radio-button [value]="element.id"></mat-radio-button>
                </td>
              </ng-container>
              <ng-container matColumnDef="LeadNo">
                <th mat-header-cell *matHeaderCellDef>{{'LEADS_MENU.LEAD_NUMBER' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.leadNumber}} </td>
              </ng-container>
              <ng-container matColumnDef="FirstName">
                  <th mat-header-cell *matHeaderCellDef>{{'COMMON.FIRST_NAME' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                </ng-container>
              <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef>{{'COMMON.LAST_NAME' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element?.lastName}} </td>
              </ng-container>
              <ng-container matColumnDef="Company">
                <th mat-header-cell *matHeaderCellDef>{{'COMMON.COMPANY' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element?.company}} </td>
              </ng-container>
              <ng-container matColumnDef="Phone">
                <th mat-header-cell *matHeaderCellDef> {{'COMMON.PHONE' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
              </ng-container>
              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef> {{'COMMON.EMAIL' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
              </ng-container>
              <ng-container matColumnDef="LeadStatus">
                <th mat-header-cell *matHeaderCellDef> {{'LEADS_MENU.LEAD_STATUS' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.leadStatus}}</td>
              </ng-container>
              <ng-container matColumnDef="CreationDate">
                <th mat-header-cell *matHeaderCellDef> {{'INQUIRY_MENU.CREATED_DATE' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{this.helpersService.getDateFromDb(element.createdDate)?.format("DD/MM/YYYY hh:mm A")}}</td>
              </ng-container>
              <!-- <ng-container matColumnDef="ApprovalStatus">
                  <th mat-header-cell *matHeaderCellDef> {{'INQUIRY_MENU.APPROVAL_STATUS' | translate}}</th>
                  <td mat-cell *matCellDef="let element"><span *ngIf="element?.approvalState === 'DRAFT'" class="badge bg-status-draft" >Draft</span><span *ngIf="element?.approvalState === 'APPROVED'"  class="badge bg-green">Approved</span></td>
                </ng-container> -->

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

              </mat-radio-group>

            </table>
              <div>

              </div>
              <mat-paginator
              class="customPaginator"
              [pageSizeOptions]="[5, 10]"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [length]="totalItems"
              (page)="onPageChange($event)"
              showFirstLastButtons
              aria-label="Select page of periodic elements"
            >
            </mat-paginator>
          </div>
      </div>
  </div>
  <div class="modal-footer pe-4">
  <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="handleCancel()">
      {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
  </button>
  <button mat-flat-button color="primary" class="flat-info-btn" *ngIf="isLoading">
    <mat-spinner diameter="20"></mat-spinner>
  </button>&nbsp;&nbsp;
  <button mat-flat-button
  color="primary"
  class="flat-info-btn btn-text-size"
  (click)="onClickcChooseLead()"
  [disabled]="!selectedLeadId"
  >
  {{ 'COMMON.SELECT' | translate }}<mat-icon>check_box</mat-icon>
  </button>
  </div>
</ng-template>

<app-quick-create-lead-modal></app-quick-create-lead-modal>
