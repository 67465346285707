import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  AccountPaginated,
  AccountGroupedList,
} from '../models/account-list.model';

@Injectable({
  providedIn: 'root',
})
export class AccountListService {
  private apiAccountUrl = 'api/Account';

  constructor(public baseDataService: BaseDataService) {}

  getAllAccounts(
    baseFilterVm: BaseFilterVm
  ): Observable<AccountPaginated | AccountGroupedList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiAccountUrl}/getAllAccounts`,
      baseFilterVm
    );
  }

  deleteAccount(accountId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiAccountUrl}/${accountId}`
    );
  }

  bulkAccountsDelete(ids: string[]): Observable<any> {
    return this.baseDataService.makeBulkDeleteCall(
      `${this.apiAccountUrl}/deleteRangeAccountsAsync`,
      ids
    );
  }

  accountExcelExport(filter: BaseFilterVm): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiAccountUrl}/accountExcelExport`,
      filter
    );
  }

  accountTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiAccountUrl}/templateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  accountUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiAccountUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }
}
