<div class="row" cdkDropListGroup>
  <div class="col-md-4">
    <div class="header-container">
      <div class="row">
        <div class="col-md-12"><h5>Advertisement</h5></div>
      </div>
      <div class="row">
        <div class="col-md-6 sub-item-color">OMR 121,212.00</div>
        <div class="col-md-6 d-flex flex-row-reverse sub-item-color">
          2 Leads
        </div>
      </div>
    </div>

    <div
      cdkDropList
      [cdkDropListData]="todo"
      class="example-list mt-3"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="example-box" *ngFor="let item of todo" cdkDrag>
        <div class="row ">
          <div class="col-md-1">
            <img
              src="../../../assets/images/user/img_avatar.png"
              class="user_img"
              alt="User"
            />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12"><b>Mohomed Nuran</b></div>
              <div class="col-md-12">Osos PVT Co</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <h5>Mohomed Michal</h5>
          <span>Title: Samspanle Inquiry</span>
          <span>spanhone: +94756253586</span>
          <span>Email: samspanlegmail.com</span>
          <span>Mobile: 0766356856</span>
        </div>
        <div class="row mt-2">
          <div class="col-md-6 sub-item-color">Jan 17, 2023 03:06 PM</div>
          <div class="col-md-6 d-flex flex-row-reverse">
            <button
              class="tableInnerMenuBtn"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span"
                  ><mat-icon>visibility</mat-icon>&nbsp;{{
                    'COMMON.VIEW' | translate
                  }}</span
                >
              </button>
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span menu-custom-span-red"
                  ><mat-icon>delete</mat-icon>&nbsp;{{
                    'COMMON.DELETE' | translate
                  }}</span
                >
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="header-container">
      <div class="row">
        <div class="col-md-12"><h5>Cold Call</h5></div>
      </div>
      <div class="row">
        <div class="col-md-6 sub-item-color">OMR 121,212.00</div>
        <div class="col-md-6 d-flex flex-row-reverse sub-item-color">
          2 Leads
        </div>
      </div>
    </div>

    <div
      cdkDropList
      [cdkDropListData]="todo"
      class="example-list mt-3"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="example-box" *ngFor="let item of todo" cdkDrag>
        <div class="row">
          <div class="col-md-1">
            <img
              src="../../../assets/images/user/img_avatar.png"
              class="user_img"
              alt="User"
            />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12"><b>Mohomed Nuran</b></div>
              <div class="col-md-12">Osos PVT Co</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <h5>Mohomed Michal</h5>
          <span>Title: Samspanle Inquiry</span>
          <span>spanhone: +94756253586</span>
          <span>Email: samspanlegmail.com</span>
          <span>Mobile: 0766356856</span>
        </div>
        <div class="row mt-2">
          <div class="col-md-6 sub-item-color">Jan 17, 2023 03:06 PM</div>
          <div class="col-md-6 d-flex flex-row-reverse">
            <button
              class="tableInnerMenuBtn"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span"
                  ><mat-icon>visibility</mat-icon>&nbsp;{{
                    'COMMON.VIEW' | translate
                  }}</span
                >
              </button>
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span menu-custom-span-red"
                  ><mat-icon>delete</mat-icon>&nbsp;{{
                    'COMMON.DELETE' | translate
                  }}</span
                >
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="header-container">
      <div class="row">
        <div class="col-md-12"><h5>Employee Referral</h5></div>
      </div>
      <div class="row">
        <div class="col-md-6 sub-item-color">OMR 121,212.00</div>
        <div class="col-md-6 d-flex flex-row-reverse sub-item-color">
          2 Leads
        </div>
      </div>
    </div>

    <div
      cdkDropList
      [cdkDropListData]="todo"
      class="example-list mt-3"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="example-box" *ngFor="let item of todo" cdkDrag>
        <div class="row">
          <div class="col-md-1">
            <img
              src="../../../assets/images/user/img_avatar.png"
              class="user_img"
              alt="User"
            />
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12"><b>Mohomed Nuran</b></div>
              <div class="col-md-12">Osos PVT Co</div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <h5>Mohomed Michal</h5>
          <span>Title: Samspanle Inquiry</span>
          <span>spanhone: +94756253586</span>
          <span>Email: samspanlegmail.com</span>
          <span>Mobile: 0766356856</span>
        </div>
        <div class="row mt-2">
          <div class="col-md-6 sub-item-color">Jan 17, 2023 03:06 PM</div>
          <div class="col-md-6 d-flex flex-row-reverse">
            <button
              class="tableInnerMenuBtn"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="tableInnerMenu d-flex" #menu="matMenu">
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span"
                  ><mat-icon>visibility</mat-icon>&nbsp;{{
                    'COMMON.VIEW' | translate
                  }}</span
                >
              </button>
              <button class="menu-custom-item" mat-menu-item>
                <span class="menu-custom-span menu-custom-span-red"
                  ><mat-icon>delete</mat-icon>&nbsp;{{
                    'COMMON.DELETE' | translate
                  }}</span
                >
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
