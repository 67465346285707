import { Injectable } from '@angular/core';
import * as moment from 'moment';

import {
  COLORS,
  CRM_ADMIN_ROLE,
  Toast,
  VahicleAssignmentStatus,
} from '../data/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  getDateFromDbFormatted(dateInUtc: any) {
    return moment(dateInUtc).local().format('YYYY-MM-DDTHH:mm:ssZ');
  }

  setDateFormatted(dateInLocal: Date) {
    if (dateInLocal) {
      return moment(dateInLocal).format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      return '';
    }
  }

  getDateFromDb(dateInUtc: any) {
    return dateInUtc ? moment(dateInUtc).local() : null;
  }

  getTimeFromDb(dateInUtc: any) {
    return moment(dateInUtc).local().format('hh:mm A');
  }

  getDbTime(time: any) {
    let utcTime = moment(time, 'HH:mm').format('hh:mm A');
    return utcTime;
  }

  getTimeFromDateTime(datetime: any) {
    return moment(datetime).format('hh:mm A');
  }

  getDateInputFormatted(dateInUtc: any) {
    return moment(dateInUtc).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  setDateForDb(dateInLocal: any) {
    if (dateInLocal) {
      return moment.utc(dateInLocal).format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      return null;
    }
  }

  setDateTime(date: any, time: any) {
    let utcDate = moment(date).utc().format('DD-MM-YYYY');
    // let utcTime = moment(time, 'hh:mm A').format('HH:mm');
    return utcDate + ' ' + time;
    // return utcDate + 'T' + utcTime + 'Z';
  }

  getDateFromDateTime(dateInUtc: any) {
    return moment(dateInUtc).format('YYYY-MM-DD');
  }

  // getTimeFromDb(dateInUtc: any) {
  //   return moment(dateInUtc).local().format("hh:mm A");
  // }

  showErrorToast(msg: string) {
    Toast.fire({
      icon: 'error',
      title: msg,
    });
  }
  showSuccessToast(msg: string) {
    Toast.fire({
      icon: 'success',
      title: msg,
    });
  }

  showCreateSuccessToastWithMessage(msg: string) {
    Toast.fire({
      icon: 'success',
      title:
        this.translateService.instant('COMMON.CREATED_SUCCESSFULLY') +
        '<br/>' +
        msg,
    });
  }

  showCreateSuccessToast() {
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('COMMON.CREATED_SUCCESSFULLY'),
    });
  }

  showUpdateSuccessToast() {
    Toast.fire({
      icon: 'success',
      title: this.translateService.instant('COMMON.UPDATED_SUCCESSFULLY'),
    });
  }

  getRandomColor(): string {
    return COLORS[(Math.random() * COLORS.length) | 0].toString();
  }
  // getRandomFCClass() : string{
  //   return FCLCLASSES[(Math.random() * FCLCLASSES.length) | 0].toString();
  // }

  getVehicleAssignmentStatusFromDb(status: string) {
    for (let i = 0; i < VahicleAssignmentStatus.length; i++) {
      const element = VahicleAssignmentStatus[i];
      if (element.value == status) {
        return element.name;
      }
    }
  }

  setDVehicleAssignmentStatusToDb(name: string) {
    for (let i = 0; i < VahicleAssignmentStatus.length; i++) {
      const element = VahicleAssignmentStatus[i];
      if (element.name == name) {
        return element.value;
      }
    }
  }

  toUpperCase(str: string) {
    str = str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : '';

    return str;
  }

  checkUserRole(role: string) {
    return (
      this.authService.currentUserValue?.role?.includes(role) ||
      this.authService.currentUserValue?.role?.includes(CRM_ADMIN_ROLE)
    );
  }

  preventE(event) {
    if (event.which === 101 || event.which === 69) {
      event.preventDefault();
    }
  }

  preventEAndDot(event) {
    if (event.which === 101 || event.which === 69 || event.which === 46) {
      event.preventDefault();
    }
  }

  getDateWithZeroTime(date: any) {
    return moment(date).startOf('day').format('YYYY-MM-DDT00:00:00+00:00');
  }

  addOneDay(date: string): string {
    return moment(date).add(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
  }

  maxNumberLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value.toString().length > maxLength) {
        return {
          maxlength: {
            requiredLength: maxLength,
            actualLength: value.toString().length,
          },
        };
      }
      return null;
    };
  }

  downloadPng(fileName: string, id: string) {
    const captureElement = document.querySelector('#' + id) as HTMLElement;

    html2canvas(captureElement, {
      backgroundColor: null, // Set the background color to null to make it transparent
      scale: 2, // Increase the scale to improve quality
      useCORS: true, // Enable CORS if loading images from different origins
    }).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', imageData);
      link.click();
      this.showSuccessToast('Succesfully Downloaded!');
    });
  }

  isCRMAdmin(role: string) {
    return this.authService?.currentUserValue?.role?.includes(CRM_ADMIN_ROLE);
  }

  confirmPasswordValidator(passwordField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control?.parent?.get(passwordField)?.value;
      const confirmPassword = control?.value;

      return password === confirmPassword ? null : { passwordMismatch: true };
    };
  }

  getLocalCurrency() {
    let localCurrency: string;
    localCurrency = JSON.parse(
      localStorage.getItem('currentCompany')
    )?.localCurrency;
    return localCurrency;
  }
}
