import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterData } from '../models/filter-data.model';
@Injectable({
  providedIn: 'root'
})
export class FilterSharedService {
  private subjectFilterData = new BehaviorSubject(null);
  private subjectSearchFieldChanged = new BehaviorSubject(null);
  private subjectSortByOpenModal = new BehaviorSubject(null);
  private subjectColumnOpenModal = new BehaviorSubject(null);
  private subjectgroupOpenModal = new BehaviorSubject(null);
  private subjectVisibleCoulmns = new BehaviorSubject(null);
  private subjectFilterParameters = new BehaviorSubject(null);
  private subjectFilterReset = new BehaviorSubject(null);
  private subjectApplyFilter = new BehaviorSubject(null);

  constructor() { }

  setsubjectFilterData(filterData: FilterData) {
    this.subjectFilterData.next(filterData);
  }

  getsubjectFilterData(): Observable<FilterData> {
    return this.subjectFilterData.asObservable();
  }
  setsubjectSearchFieldChanged(searchString: string) {
    this.subjectFilterData.next(searchString);
  }
  getsubjectSearchFieldChanged(): Observable<string> {
    return this.subjectFilterData.asObservable();
  }

  setsubjectSortByOpenModal(string: string) {
    this.subjectSortByOpenModal.next(string);
  }
  getsubjectSortByOpenModal(): Observable<any> {
    return this.subjectSortByOpenModal.asObservable();
  }
  setsubjectColumnOpenModal(string: string) {
    this.subjectColumnOpenModal.next(string);
  }
  getsubjectColumnOpenModal(): Observable<any> {
    return this.subjectColumnOpenModal.asObservable();
  }
  setsubjectgroupOpenModal(string: string) {
    this.subjectgroupOpenModal.next(string);
  }
  getsubjectgroupOpenModal(): Observable<any> {
    return this.subjectgroupOpenModal.asObservable();
  }
  setsubjectVisibleCoulmns(cols: string[]) {
    this.subjectgroupOpenModal.next(cols);
  }
  getsubjectVisibleCoulmns(): Observable<any> {
    return this.subjectgroupOpenModal.asObservable();
  }
  getsubjectFilterParameterOpenModal(): Observable<any> {
    return this.subjectFilterParameters.asObservable();
  }
  setsubjectFilterParameterOpenModal(string: string) {
    this.subjectFilterParameters.next(string);
  }
  getsubjectFilterReset(): Observable<boolean> {
    return this.subjectFilterReset.asObservable();
  }
  setsubjectFilterReset(flag: boolean) {
    this.subjectFilterReset.next(flag);
  }
  setsubjectApplyFilter(flag: boolean) {
    this.subjectApplyFilter.next(flag);
  }
  getsubjectApplyFilter(): Observable<boolean> {
    return this.subjectApplyFilter.asObservable();
  }

}
