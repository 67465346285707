<ng-template #template>
  <h4 class="modal-title pull-left">
    <span *ngIf="!isEdit">
      {{ 'MEETING_MENU.ADD_PARTICIPANTS' | translate }}
    </span>
    <span *ngIf="isEdit">
      {{ 'MEETING_MENU.EDIT_PARTICIPANTS' | translate }}
    </span>
  </h4>
  <div class="modal-body">
    <form [formGroup]="meetingParticipantForm">
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'MEETING_MENU.PARTICIPANT_TYPE' | translate
            }}</mat-label>
            <mat-select
              formControlName="Type"
              (selectionChange)="getMeetingParticipantsByType($event)"
            >
              <mat-option
                class="form-select-option"
                *ngFor="
                  let participantsType of participantsTypeList
                    | filter : userInformationSearch
                "
                [value]="participantsType.id"
                >{{ participantsType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="SearchText"
              (input)="getMeetingParticipantsByType()"
            />
            <button (click)="clearSearch()" mat-icon-button matSuffix>
              <mat-icon class="serach-close-icon">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div
          class="col-md-2 mt-2"
          *ngIf="
            this.fruits.length != 0 || this.meetingParticipants.length != 0
          "
        >
          <button
            mat-stroked-button
            color="primary"
            class="btn-border outline-info-btn btn-text-size"
            (click)="clearAllSelected()"
          >
            {{ 'COMMON.CLEAR_ALL' | translate }}
          </button>
        </div>
        <div class="col-md-3 mt-2">
          <span
            >All Selected ({{
              this.meetingParticipants?.length + this.fruits?.length
            }})</span
          >
        </div>
      </div>
      <div class="row tb-container">
        <ul>
          <li *ngFor="let participant of meetingParticipantTypeList">
            <span class="mt-5">
              <mat-checkbox
                (change)="selectParticipants($event.checked, participant)"
                [checked]="participant.checked"
              >
                <span class="checkbox-container"
                  ><b>{{ participant.name }} </b></span
                ><br />
                <span class="checkbox-container">
                  {{ participant.email }}
                </span>
              </mat-checkbox>
            </span>
            <hr />
          </li>
        </ul>
      </div>
      <div class="row mt-3" *ngIf="this.meetingParticipants?.length != 0">
        <div class="col-md-12">
          <h6><b>Selected Participants </b></h6>
          <div class="selected-mail-container">
            <span *ngFor="let participant of this.meetingParticipants">
              <span class="badge bg-grey">
                <span>{{
                  'Participant Type : ' +
                    getSelectedParticipantType(participant.participantTypeId)
                }}</span
                ><br />
                <span>{{ 'Email : ' + participant.participantEmail }}</span>
              </span>
              &nbsp;
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <span
          ><b>Invite external participants by email : </b>
          <span class="hint-font">Use commas to separate email addresses</span>
        </span>
        <div class="col-md-12 mt-2">
          <mat-form-field
            class="example-full-width form-input-field example-chip-list height"
            appearance="fill"
          >
            <mat-label>Add Emails</mat-label>
            <mat-chip-grid
              #chipGrid
              aria-label="Enter fruits"
              class="mail-container"
            >
              <mat-chip-row
                *ngFor="let fruit of fruits"
                (removed)="remove(fruit)"
                [editable]="true"
                (edited)="edit(fruit, $event)"
                [aria-description]="'press enter to edit ' + fruit.name"
              >
                {{ fruit.name }}
                <button
                  matChipRemove
                  [attr.aria-label]="'remove ' + fruit.name"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
              <input
                placeholder="New mail..."
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-grid>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="cancelForm()"
    >
      {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="setMeetingParticipants()"
    >
      {{ 'COMMON.DONE' | translate }}<mat-icon>done</mat-icon>
    </button>
  </div>
</ng-template>
