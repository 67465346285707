<ng-template #template>
  <h5 class="modal-title pull-left">
    <b>{{ 'CALLS_MENU.RESCHEDULE' | translate }}</b>
  </h5>
  <div class="modal-body">
    <form [formGroup]="rescheduleForm">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field
            class="example-full-width form-input-field mat-form-field-readonly"
          >
            <mat-label>{{ callDetails?.callToTypeName }}</mat-label>
            <input type="text" matInput formControlName="CallTo" readonly />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'EMAIL_MENU.DATE' | translate }}</mat-label>
            <input
              formControlName="Date"
              matInput
              [matDatepicker]="picker1"
              readonly
              [min]="getCurrentDate()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.TIME' | translate }}</mat-label>
            <input
              matInput
              formControlName="Time"
              [ngxMatTimepicker]="pickerA"
              readonly
              [min]="
                helpersService.getDateFromDateTime(getCurrentDate()) ==
                helpersService.getDateFromDateTime(
                  rescheduleForm.get('Date').value
                )
                  ? helpersService.getTimeFromDateTime(getCurrentDate())
                  : null
              "
            />
            <ngx-mat-timepicker #pickerA> </ngx-mat-timepicker>
            <mat-icon matSuffix (click)="pickerA.open()">
              watch_later
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'CALLS_MENU.DESCRIPTION' | translate }}</mat-label>
            <input type="text" matInput formControlName="Note" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-12 mt-4 d-flex align text-align-right flex-row-reverse"
        >
          <div class="example-button-row">
            <button
              mat-stroked-button
              color="primary"
              class="btn-border outline-info-btn btn-text-size"
              (click)="cancelForm()"
            >
              {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
            </button>
            <button
              mat-flat-button
              class="flat-info-btn"
              color="primary"
              *ngIf="isLoading"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </button>
            <button
              mat-flat-button
              color="primary"
              class="flat-info-btn btn-text-size"
              (click)="rescheduleCall(rescheduleForm.value)"
              [disabled]="!rescheduleForm.valid"
              *ngIf="!isLoading"
            >
              {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
