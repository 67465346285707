<div class="row mt-4">
  <div class="col-md-12">
    <div class="card">
      <div class="container">
        <div class="row mt-4">
          <div class="col-md-6 mt-3">
            <div>
              <h4>
                <b>{{ 'COMMON.HISTORY' | translate }}</b>
              </h4>
            </div>
          </div>

          <div class="col-md-6 d-flex flex-row-reverse">
            <div class="mt-4">
              <button class="reset_button" (click)="handleSearch()">
                <mat-icon class="search-icons">search</mat-icon></button
              >&nbsp; &nbsp;
              <button class="reset_button" (click)="handleReset()">
                <mat-icon class="search-icons">restart_alt</mat-icon>
              </button>
            </div>
            &nbsp; &nbsp; &nbsp;
            <mat-form-field appearance="fill">
              <mat-label>{{ 'COMMON.SELECT_A_DATE_RANGE' | translate }}</mat-label>
              <mat-date-range-input
                [formGroup]="range"
                [rangePicker]="picker"
                [max]="maxDate"
                [min]="minDate"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                  readonly
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error
                *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                >Invalid start date</mat-error
              >
              <mat-error
                *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                >Invalid end date</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <app-loader *ngIf="isLoading"></app-loader>
      <div class="body">
        <div class="container history-container" (scroll)="onScroll($event)">
          <div class="row">
            <div class="col-md-11">
              <div *ngFor="let dailyAuditEvent of auditEventList?.items">
                <div>
                  <b>{{ dailyAuditEvent?.key | date }}</b>
                </div>
                <ul class="left-timeline">
                  <li *ngFor="let auditEvent of dailyAuditEvent?.items">
                    <time class="left-time"
                      ><span>{{
                        auditEvent.eventTime | date : 'HH:mm a'
                      }}</span>
                    </time>
                    <div>
                      <img
                        class="left-icon"
                        src="https://fleetblobcz.blob.core.windows.net/fmp/img_avatar.png"
                        alt=""
                      />
                    </div>
                    <div class="left-label">
                      <div *ngFor="let event of auditEvent?.changedProperties">
                        <span [innerHTML]="event"></span>
                      </div>

                      <h2>
                        <a class="no-pointer">by {{ auditEvent.changedBy }} </a>
                      </h2>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
