// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://crmuatapi.codezync.net/',
  authUrl:"https://kcauth.codezync.net",
  clientDBId:"a45668a5-c519-48df-8b26-36734cdc0024",
  clientId:"crm-uat-client",
  clientSecret:"hrRWZksgaDFt7REO0M046WuZb8Rm7qhc",
  realm:"crm-uat-realm",
  recaptcha: {
    siteKey: '6LeWSE0pAAAAAEX-YxKyQL98QlcNJceMEX3oMD1o',
  },
  signalRUrl:"https://crmuatapi.codezync.net/notificationHub",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
