import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonChooseListSharedService {

  private subjectOpenLeadListModal = new BehaviorSubject(null);
  private subjectLeadListTableChanged = new BehaviorSubject(null);
  private subjectSelectedLeadId = new BehaviorSubject(null);

  private subjectOpenAccountListModal = new BehaviorSubject(null);
  private subjectAccountListTableChanged = new BehaviorSubject(null);
  private subjectSelectedAccountId = new BehaviorSubject(null);

  private subjectOpenContactListModal = new BehaviorSubject(null);
  private subjectContactListTableChanged = new BehaviorSubject(null);
  private subjectSelectedContactLeadId = new BehaviorSubject(null);

  private subjectOpenCampaignListModal = new BehaviorSubject(null);
  private subjectCampaignListTableChanged = new BehaviorSubject(null);
  private subjectSelectedCampaignId = new BehaviorSubject(null);

  private subjectOpenDealListModal = new BehaviorSubject(null);
  private subjectDealListTableChanged = new BehaviorSubject(null);
  private subjectSelectedDealId = new BehaviorSubject(null);

  constructor() { }

  //lead
  setSubjectOpenLeadListModal(string: string) {
    this.subjectOpenLeadListModal.next(string);
  }
  getSubjectOpenLeadListModal(): Observable<any> {
    return this.subjectOpenLeadListModal.asObservable();
  }
  setSubjectLeadListTableChanged(flag: string) {
    this.subjectLeadListTableChanged.next(flag);
  }
  getSubjectLeadListTableChanged(): Observable<any> {
    return this.subjectLeadListTableChanged.asObservable();
  }
  setSubjectSelectedLeadId(string: string) {
    this.subjectSelectedLeadId.next(string);
  }
  getSubjectSelectedLeadId(): Observable<any> {
    return this.subjectSelectedLeadId.asObservable();
  }

  //campaign
  setSubjectOpenCampaignListModal(string: string) {
    this.subjectOpenCampaignListModal.next(string);
  }
  getSubjectOpenCampaignListModal(): Observable<any> {
    return this.subjectOpenCampaignListModal.asObservable();
  }
  setSubjectCampaignListTableChanged(flag: string) {
    this.subjectCampaignListTableChanged.next(flag);
  }
  getSubjectCampaignListTableChanged(): Observable<any> {
    return this.subjectCampaignListTableChanged.asObservable();
  }
  setSubjectSelectedCampaignId(string: string) {
    this.subjectSelectedCampaignId.next(string);
  }
  getSubjectSelectedCampaignId(): Observable<any> {
    return this.subjectSelectedCampaignId.asObservable();
  }

  //contact
  setSubjectContactListModal(string: string) {
    this.subjectOpenContactListModal.next(string)
  }
  getSubjectContactListModal(): Observable<any> {
    return this.subjectOpenContactListModal.asObservable();
  }
  setSubjectContactListTableChanged(flag: string) {
    this.subjectContactListTableChanged.next(flag);
  }
  getSubjectContactListTableChanged(): Observable<any> {
    return this.subjectContactListTableChanged.asObservable();
  }
  setSubjectSelectedContactId(string: string) {
    this.subjectSelectedContactLeadId.next(string);
  }
  getSubjectSelectedContactId(): Observable<any> {
    return this.subjectSelectedContactLeadId.asObservable();
  }

  //deal
  setSubjectDealListModal(string: string) {
    this.subjectOpenDealListModal.next(string)
  }
  getSubjectDealListModal(): Observable<any> {
    return this.subjectOpenDealListModal.asObservable();
  }
  setSubjectDealListTableChanged(flag: string) {
    this.subjectDealListTableChanged.next(flag);
  }
  getSubjectDealListTableChanged(): Observable<any> {
    return this.subjectDealListTableChanged.asObservable();
  }
  setSubjectSelectedDealId(string: string) {
    this.subjectSelectedDealId.next(string);
  }
  getSubjectSelectedDealId(): Observable<any> {
    return this.subjectSelectedDealId.asObservable();
  }

  //Account
  setSubjectOpenAccountListModal(string: string) {
    this.subjectOpenAccountListModal.next(string);
  }
  getSubjectOpenAccountListModal(): Observable<any> {
    return this.subjectOpenAccountListModal.asObservable();
  }
  setSubjectAccountListTableChanged(flag: string) {
    this.subjectAccountListTableChanged.next(flag);
  }
  getSubjectAccountListTableChanged(): Observable<any> {
    return this.subjectAccountListTableChanged.asObservable();
  }
  setSubjectSelectedAccountId(string: string) {
    this.subjectSelectedAccountId.next(string);
  }
  getSubjectSelectedAccountId(): Observable<any> {
    return this.subjectSelectedAccountId.asObservable();
  }

}
