import { Component } from '@angular/core';
import { DocumentUpload } from '../../../../models/document-upload.model';
import { DocumentUploadSharedService } from '../../../../services/document-upload-shared.service';
import { ReplaySubject, Subject, takeUntil, Subscription } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-attachment-grid-view',
  templateUrl: './attachment-grid-view.component.html',
  styleUrls: ['./attachment-grid-view.component.scss']
})
export class AttachmentGridViewComponent {
  attachmentList: DocumentUpload[];
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  documentAttachmentDataSubject: Subscription

  constructor(
    private documentUploadSharedService: DocumentUploadSharedService,
    private helpersService: HelpersService
  )
  {
    this.documentAttachmentDataSubject = documentUploadSharedService
    .getsubjectDocumentAttachmentList()
    .pipe(takeUntil(this.destroy))
    .subscribe((list: DocumentUpload[]) => {
      if (list != null) {
        this.attachmentList = list;
      }
    });
  }

  removeAttachment(index: number) {
    this.attachmentList.splice(index, 1);
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(this.attachmentList)
    this.helpersService.showSuccessToast("Attachment Record Removed SuccessFully");
  }
}
