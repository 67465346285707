import { Component, ViewChild } from '@angular/core';
import { CommonChooseListSharedService } from 'src/app/shared/services/common-choose-list-shared.service';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { Contact } from 'src/app/home/deals/deal-view/models/deal-view.model';
import { LeadsSharedService } from 'src/app/home/leads/services/leads-shared.service';
import { LeadsService } from 'src/app/home/leads/services/leads.service';
import { Constants } from 'src/app/shared/data/constants';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import {
  ContactGroupedDataSource,
  ContactGroupedList,
  ContactList,
  ContactPaginated,
} from 'src/app/home/contacts/contact-list/models/contact-list.model';
import { ContactListService } from 'src/app/home/contacts/contact-list/services/contact-list.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';

@Component({
  selector: 'app-contact-list-modal',
  templateUrl: './contact-list-modal.component.html',
  styleUrls: ['./contact-list-modal.component.sass'],
})
export class ContactListModalComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  contactListSubject: Subscription;
  contactIdSubject: Subscription;
  contactListChangedSubject: Subscription;
  contactList: ContactList[] = [];
  displayedColumns: string[] = [
    'Select',
    'FirstName',
    'LastName',
    'Phone',
    'Email',
    'AccountName',
    'Assistant',
    'ApprovalStatus',
  ];
  dataSource = new MatTableDataSource<ContactList>(this.contactList);
  modalRef: BsModalRef;
  baseFilterVm: BaseFilterVm;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('template', { static: true }) template: any;
  isLoading: boolean = false;
  selectedContactId: string = null;
  pageSize: number = 10;
  pageIndex: number = 1;
  pageLength: number = 0;
  totalItems: number = 0;
  contactPaginated: ContactPaginated;
  contactGroupedList: ContactGroupedList[] = [];
  dataSources: ContactGroupedDataSource[];

  constructor(
    private paginatorIntl: MatPaginatorIntl,
    public helpersService: HelpersService,
    private modalService: BsModalService,
    private commonChooseListSharedService: CommonChooseListSharedService,
    private contactsService: ContactListService,
    private quickCreateSharedService: QuickCreateSharedService
  ) {
    this.contactListSubject = commonChooseListSharedService
      .getSubjectContactListModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
          this.getAllContacts();
        }
      });

    this.contactIdSubject = this.commonChooseListSharedService
      .getSubjectSelectedContactId()
      .pipe(takeUntil(this.destroy))
      .subscribe((contactId: string) => {
        this.selectedContactId = contactId;
      });

    this.contactListChangedSubject = this.commonChooseListSharedService
      .getSubjectContactListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllContacts();
        }
      });

    this.contactListChangedSubject = this.quickCreateSharedService
      .getSubjectChooseContactListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllContacts();
        }
      });
  }

  ngOnInit(): void {}

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setFilterData() {
    this.baseFilterVm = {
      searchText: '',
      groupBy: '',
      sortBy: '',
      filters: [
        {
          condition: 'Equal',
          filterBy: 'ApprovalStatus',
          filterClause: 'AND',
          value: 'Approved',
          dataType: 'STRING',
          lookupValueList: [],
        },
      ],
      isDescending: false,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortByList: [],
    };
  }

  getAllContacts() {
    this.setFilterData();
    this.contactGroupedList = [];
    this.isLoading = true;
    this.dataSources = [];
    this.dataSource.data = [];
    this.contactsService
      .getAllContacts(this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: ContactPaginated | ContactGroupedList[]) => {
          if ('totalItems' in res) {
            this.contactPaginated = res;
            this.contactList = res.items;
            if (Array.isArray(res.items)) {
              this.dataSource = new MatTableDataSource<ContactList>(res.items);
            }
            this.totalItems = res.totalItems;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllContacts();
  }

  onRadioButtonChange(event: Event): void {
    console.log('Selected option:', this.selectedContactId);
  }

  onClickChooseContact() {
    this.commonChooseListSharedService.setSubjectSelectedContactId(
      this.selectedContactId
    );
    this.modalRef.hide();
    this.commonChooseListSharedService.setSubjectContactListModal(null);
  }

  createContact() {
    this.quickCreateSharedService.setSubjectQuickCreateContactOpenModal('Open');
  }

  handleCancel() {
    this.modalRef.hide();
    this.selectedContactId = null;
    this.commonChooseListSharedService.setSubjectContactListModal(null);
  }

  ngOnDestroy(): void {
    this.commonChooseListSharedService.setSubjectContactListModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }
}
