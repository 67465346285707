import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData } from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-column',
  templateUrl: './filter-column.component.html',
  styleUrls: ['./filter-column.component.scss'],
})
export class FilterColumnComponent implements OnDestroy {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];
  columnsSearch: string;
  filterdColumnData: string[] = [];

  @Input() filterForm: FormGroup;
  /**
   *
   */
  constructor(
    private modalService: BsModalService,
    private filterSharedService: FilterSharedService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.filterDataSubject = filterSharedService
      .getsubjectFilterData()
      .pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;
          this.coloumnsData = [...(filterData.ColumnsData || [])];
        }
      });

    this.filterDataSubject = filterSharedService
      .getsubjectColumnOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal();
        }
      });

    this.filterDataSubject = filterSharedService
      .getsubjectFilterReset()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {
          this.resetFilter(true);
        }
      });
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.initializeForm();
  }
  initializeForm() {
    var formvalues = [];
    this.coloumnsData.forEach((element) => {
      formvalues.push(new FormControl(element.data));
    });

    this.filterForm?.addControl('columns', new FormArray(formvalues));
  }
  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right ',
    });
  }

  searchChange(event: any) {
    const filterValue = event.target.value.toLowerCase();

    this.coloumnsData = [...this.filterData.ColumnsData].filter((option) =>
      this.translateService
        .instant(option.translate)
        .toLowerCase()
        .includes(filterValue)
    );
  }

  resetFilter(isClearFromMainFilterMenu = false) {
    this.filterdColumnData = [];
    this.columnsSearch = '';
    this.coloumnsData = [...this.filterData?.ColumnsData];

    var checkArray: FormArray = this.filterForm?.get('columns') as FormArray;

    var arr = this.coloumnsData?.map((a) => a.data);

    checkArray?.clear();

    this.coloumnsData?.forEach((element) => {
      checkArray.push(new FormControl(element.data));
    });

    if (!isClearFromMainFilterMenu) {
      this.filterSharedService.setsubjectApplyFilter(true);
    }
  }

  onCheckboxChange(e) {
    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;

    var arr = this.coloumnsData.map((a) => a.data);

    if (e.checked && !checkArray.value.includes(e.source.id)) {
      checkArray.insert(arr.indexOf(e.source.id), new FormControl(e.source.id));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.source.id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.filterdColumnData = [];
    this.coloumnsData
      .map((x) => x.data)
      .forEach((t) => {
        var index = this.filterForm?.get('columns')?.value.indexOf(t);
        if (index != -1) {
          this.filterdColumnData.push(t);
        }
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;
    moveItemInArray(this.coloumnsData, event.previousIndex, event.currentIndex);
    this.mapColoumns();
  }

  mapColoumns() {
    var formvalues = [];
    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;

    const values = checkArray.controls.map((control) => control.getRawValue());
    this.coloumnsData.forEach((element) => {
      if (values.includes(element.data)) {
        formvalues.push(new FormControl(element.data));
      }
    });

    this.filterForm?.setControl('columns', new FormArray(formvalues));
  }

  applyFilter() {
    this.filterSharedService.setsubjectApplyFilter(true);
  }

  ngOnDestroy() {
    this.filterSharedService.setsubjectColumnOpenModal(null);
    this.destroy.next(null);
    this.modalRef?.hide();
  }
}
