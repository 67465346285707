import Swal from 'sweetalert2';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Toast } from 'src/app/shared/data/constants';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { DealAssignmentService } from '../../../services/deal-assignment.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { DealAssignmentSharedService } from 'src/app/shared/services/deal-assignment-shared.service';
import {
  DealsByReferenceIdList,
  DealsByReferenceIdPaginated,
} from 'src/app/shared/models/deal-assignment.model';

@Component({
  selector: 'app-deal-assignment-list',
  templateUrl: './deal-assignment-list.component.html',
  styleUrls: ['./deal-assignment-list.component.scss'],
})
export class DealAssignmentListComponent {
  pageSize = 10;
  searchForm: FormGroup;
  pageIndex: number = 0;
  totalItems: number = 0;
  @Input() module: string;
  searchText: string = '';
  isLoading: boolean = false;
  baseFilterVm: BaseFilterVm;
  @Input() referenceId: string;
  isAssignVisible: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dealsByReferenceIdList: DealsByReferenceIdList[] = [];
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  dataSource = new MatTableDataSource<DealsByReferenceIdList>(
    this.dealsByReferenceIdList
  );
  displayedColumns: string[] = [
    'DealName',
    'Amount',
    'Stage',
    'Probability',
    'ClosingDate',
    'Type',
    'ApprovalState',
    'Action',
  ];

  constructor(
    private fb: FormBuilder,
    public helpersService: HelpersService,
    private translateService: TranslateService,
    private dealAssignmentService: DealAssignmentService,
    private quickCreateSharedService: QuickCreateSharedService,
    private dealAssignmentSharedService: DealAssignmentSharedService
  ) {
    this.dealAssignmentSharedService
      .getSubjectAssignExistingDealsTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((falg: boolean) => {
        if (falg) {
          this.getAllDealsByReferenceId();
        }
      });
  }

  ngOnInit(): void {
    this.getAllDealsByReferenceId();
    this.createForm();
    this.isAssignedVisible();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onSearchChange(searchText: any) {
    this.searchText = searchText;
    this.getAllDealsByReferenceId();
  }

  addExistingDeal() {
    this.dealAssignmentSharedService.setSubjectAssignExistingDealsOpenModal(
      'Open'
    );
  }

  addNewDeal() {
    this.quickCreateSharedService.setSubjectQuickCreateDealOpenModal('Open');
  }

  openEdit(id: string) {
    this.dealAssignmentSharedService.setSubjectEditDealsOpenModal(id);
  }

  createForm() {
    this.searchForm = this.fb.group({
      Search: [''],
    });
  }

  getAllDealsByReferenceId() {
    this.isLoading = true;

    this.baseFilterVm = {
      searchText: this.searchText,
      isDescending: false,
      sortBy: '',
      groupBy: '',
      filters: [],
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };

    if (this.referenceId && this.module) {
      this.dealAssignmentService
        .getAllDealsByReferenceId(
          this.referenceId,
          this.module,
          this.baseFilterVm
        )
        .pipe(takeUntil(this.destroy))
        .subscribe(
          (res: DealsByReferenceIdPaginated) => {
            if (res.items) {
              this.dataSource = new MatTableDataSource<DealsByReferenceIdList>(
                res.items
              );
            }
            this.totalItems = res.totalItems;
            this.dealAssignmentSharedService.setSubjectNumberOfAssignExistingDeals(
              this.totalItems
            );
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
          }
        );
    }
  }

  isAssignedVisible() {
    this.isAssignVisible = true;
    if (this.module == 'CONTACT') {
      this.isAssignVisible = false;
    }
  }

  deleteOrUnassignDeal(id: string) {
    Swal.fire({
      title: this.translateService.instant(
        this.module != 'CONTACT'
          ? 'COMMON.DELETE_OR_UNASSIGN_DEAL_RECORD'
          : 'COMMON.DELETE_DEAL_RECORD'
      ),
      text: this.translateService.instant(
        this.module != 'CONTACT'
          ? 'COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_OR_UNASSIGN'
          : 'COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_THIS_ACTION_CANNOT_BE_UNDONE'
      ),
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: this.module != 'CONTACT' ? true : false,
      confirmButtonColor: '#e0272a',
      denyButtonColor: '#e0272a',
      cancelButtonColor: 'transparent',
      confirmButtonText: this.translateService.instant('COMMON.DELETE'),
      cancelButtonText: this.translateService.instant('COMMON.CANCEL'),
      denyButtonText: this.translateService.instant('COMMON.UNASSIGN'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      } else if (result.isDenied) {
        this.unAssign(id, this.module);
      }
    });
  }

  delete(id: string) {
    this.dealAssignmentService
      .deleteDeal(id)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          Toast.fire({
            icon: 'success',
            title: this.translateService.instant('COMMON.DELETED_SUCCESSFULLY'),
          });
          this.getAllDealsByReferenceId();
        },
        (err) => {}
      );
  }

  unAssign(id: string, module: string) {
    this.dealAssignmentService
      .unAssignDeal(id, module)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          Toast.fire({
            icon: 'success',
            title: this.translateService.instant(
              'COMMON.UNASSIGNED_SUCCESSFULLY'
            ),
          });
          this.getAllDealsByReferenceId();
        },
        (err) => {}
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getAllDealsByReferenceId();
  }
}
