import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { QuickCreateLead } from '../../../models/quick-create.model';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QuickCreateSharedService } from '../../../services/quick-create-shared.service';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { QuickCreateService } from '../../../services/quick-create.service';

@Component({
  selector: 'app-quick-create-lead-modal',
  templateUrl: './quick-create-lead-modal.component.html',
  styleUrls: ['./quick-create-lead-modal.component.scss'],
})
export class QuickCreateLeadModalComponent {
  @ViewChild('template', { static: true }) template: any;
  leadCreateSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  leadForm: FormGroup;
  isLoading: boolean = false;
  quickCreateLeadReq: QuickCreateLead;
  leadSourceSearch: string;
  userInformationList: LookUpElement[] = [];
  userInformationSearch: string = '';

  leadsSourceLookup: LookUpElement[] = [];
  leadsStatusLookup: LookUpElement[];

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private lookupsService: LookupsService,
    private quickCreateSharedService: QuickCreateSharedService,
    private quickCreateService: QuickCreateService
  ) {
    this.leadCreateSubject = quickCreateSharedService
      .getSubjectQuickCreateLeadOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.quickCreateSharedService.setSubjectQuickCreateLeadOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createLeadForm();
    this.getLookupList();
    this.getUserInformationList();
  }

  getLookupList() {
    this.getLeadsSourceList();
    this.getLeadsStatusList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      // class: 'modal-dialog-centered modal-lg',
      class: 'modal-dialog-right modal-lg',
    });
  }

  createLeadForm() {
    this.leadForm = this.fb.group({
      LeadOwner: [''],
      Campany: [''],
      FirstName: [''],
      LastName: [''],
      LeadName: [''],
      Email: [
        '',
        [
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      Phone: ['', [Validators.pattern('[- +()0-9]{10,12}')]],
      LeadSourceId: [null],
      LeadStatusId: [null],
    });
  }

  getCurrentDate() {
    return new Date();
  }

  cancelForm() {
    this.modalRef.hide();
    this.leadForm.reset();
  }

  getLeadsSourceList() {
    this.lookupsService
      .getLeadMasterLookup('LeadSource')
      .pipe(takeUntil(this.destroy))
      .subscribe((leadsSourceLookup: LookUpElement[]) => {
        this.leadsSourceLookup = leadsSourceLookup;
      });
  }

  getLeadsStatusList() {
    this.lookupsService
      .getLeadMasterLookup('LeadStatus')
      .pipe(takeUntil(this.destroy))
      .subscribe((leadsStatusLookup: LookUpElement[]) => {
        this.leadsStatusLookup = leadsStatusLookup;
      });
  }

  getUserInformationList() {
    this.lookupsService.getUserInformationLookUp().pipe(takeUntil(this.destroy))
    .subscribe((userInformation: LookUpElement[]) => {
      this.userInformationList = userInformation;
    });
  }

  createLead(formData: any) {
    this.setReqBody(formData);
    this.saveLeadInDB();
  }

  setReqBody(formData: any) {
    this.quickCreateLeadReq = {
      leadOwnerId: formData.LeadOwner,
      company: formData.Campany,
      firstName: formData.FirstName,
      lastName: formData.LastName,
      leadName: formData.LeadName,
      email: formData.Email,
      phone: formData.Phone ?? '',
      leadSourceId: formData.LeadSourceId,
      leadStatusId: formData.LeadStatusId,
    };
  }

  saveLeadInDB() {
    this.isLoading = true;
    this.quickCreateService.quickCreateLead(this.quickCreateLeadReq).subscribe(
      (res: any) => {
        if (res) {
          this.cancelForm();
          this.helpersService.showSuccessToast('Created Successfully');
          this.quickCreateSharedService.setSubjectQuickCreateLeadOpenModal(
            null
          );
          this.quickCreateSharedService.setSubjectChooseLeadListTableChanged(
            true
          );
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
