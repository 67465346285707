import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  LookUpElement,
  CompanyLookupModel,
  WidgetLookUpElement,
  CountryCodeLookUpElement,
  CountryPhoneCodeLookUpElement,
  ExternalConfigurationLookUpElement,
  LiquidVariablesConfigurationLookUpElement
} from '../models/common-data.model';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  private apiDealUrl = 'api/Deal/lookup';
  private apiCallUrl = 'api/CallMaster/lookUp';
  private apiCampaignUrl = 'api/Campaign/lookUp';
  private apiLeadLookupUrl = 'api/Leads/lookup';
  private apiTaskMasterUrl = 'api/TaskMaster/lookUp';
  private apiCountryLookupUrl = 'api/Country/lookup';
  private apiContactLookupUrl = 'api/Contact/lookup';
  private apiAccountLookupUrl = 'api/Account/lookup';
  private apiCompanyProfileUrl = 'api/CompanyProfile';
  private apiDealMasterLookupUrl = 'api/DealMaster/lookUp';
  private apiLeadMasterLookupUrl = 'api/LeadMaster/lookup';
  private apiCampaignMasterUrl = 'api/CampaignMaster/lookUp';
  private apiAccountMasterLookupUrl = 'api/AccountMaster/lookUp';
  private apiMeetingMasterLookupUrl = 'api/MeetingMaster/lookUp';
  private apiInquiryMasterLookupUrl = 'api/InquiryMaster/lookup';
  private apiApprovalGroupLookupUrl = 'api/ApprovalGroup/lookUp';
  private apiApprovalActionLookupUrl = 'api/ApprovalAction/lookUp';
  private apiCompanyProfileLookupUrl = 'api/CompanyProfile/lookUp';
  private apiCountryCodeLookupUrl = 'api/Country/countryCodeLookup';
  private apiUserInformationLookupUrl = 'api/UserInformation/lookUp';
  private apiRelatedToMasterLookUpUrl = 'api/RelatedToMaster/lookUp';
  private apiReminderRepeatMasterUrl = 'api/ReminderRepeatMaster/lookUp';
  private apiExternalConfigurationLookupUrl =
    'api/ExternalConfiguration/lookUp';
  private apiCountryPhoneCodeLookUpUrl =
    'api/Country/countryPhoneCodeLookUpAsync';
  private apiNotificationEventConfigurationLookupUrl =
    'api/NotificationEventConfiguration/lookUp';
  private apiNotificationModuleConfigurationLookupUrl =
    'api/NotificationModuleConfiguration/lookUp';
  private apiNotificationLiquidVariablesConfigurationLookupUrl =
    'api/NotificationLiquidVariablesConfiguration/lookUp';
  private apiWidgetUrl = 'api/Widget/lookUp';

  constructor(private baseDataService: BaseDataService) {}

  getInquiryMasterLookup(type: string): Observable<LookUpElement[]> {
    let inquiryType = type;
    return this.baseDataService.makeGetCall(
      `${this.apiInquiryMasterLookupUrl}?inquiryType=${inquiryType}`
    );
  }

  getCountryLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCountryLookupUrl}`);
  }

  getLeadMasterLookup(type: string): Observable<LookUpElement[]> {
    let leadType = type;
    return this.baseDataService.makeGetCall(
      `${this.apiLeadMasterLookupUrl}?leadType=${leadType}`
    );
  }

  getDealMasterLookup(type: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiDealMasterLookupUrl}?dealType=${type}`
    );
  }

  getAccountMasterLookup(type: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiAccountMasterLookupUrl}?accountType=${type}`
    );
  }

  getContactLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiContactLookupUrl}`);
  }

  getAccountLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiAccountLookupUrl}`);
  }

  getLeadLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiLeadLookupUrl}`);
  }

  getTaskMasterLookup(type: string): Observable<LookUpElement[]> {
    let taskType = type;
    return this.baseDataService.makeGetCall(
      `${this.apiTaskMasterUrl}?taskType=${taskType}`
    );
  }

  getCampaignLookup(type: string): Observable<LookUpElement[]> {
    let campaignType = type;
    return this.baseDataService.makeGetCall(
      `${this.apiCampaignMasterUrl}?campaignType=${campaignType}`
    );
  }

  getCampaignListLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCampaignUrl}`);
  }

  getCallMasterLookup(type: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCallUrl}?callType=${type}`
    );
  }

  getRelatedToMasterLookup(type: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiRelatedToMasterLookUpUrl}?type=${type}`
    );
  }

  getDealListLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiDealUrl}`);
  }

  getCountryCodeLookup(): Observable<CountryCodeLookUpElement[]> {
    return this.baseDataService.makeGetCall(`${this.apiCountryCodeLookupUrl}`);
  }

  getCountryPhoneCodeLookUp(): Observable<CountryPhoneCodeLookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCountryPhoneCodeLookUpUrl}`
    );
  }

  getMeetingMasterLookup(type: string): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiMeetingMasterLookupUrl}?meetingType=${type}`
    );
  }

  getUserInformationLookUp(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiUserInformationLookupUrl}`
    );
  }

  getExternalConfigurationLookup(
    module: string
  ): Observable<ExternalConfigurationLookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiExternalConfigurationLookupUrl}?module=${module}`
    );
  }

  getNotificationModuleConfigurationLookUp(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiNotificationModuleConfigurationLookupUrl}`
    );
  }

  getNotificationEventConfigurationLookup(
    moduleId?: string,
    eventId: string = null
  ): Observable<LookUpElement[]> {
    let secondaryUrl = eventId
      ? '?moduleId=' + moduleId + '&' + 'eventId=' + eventId
      : '?moduleId=' + moduleId;

    return this.baseDataService.makeGetCall(
      `${this.apiNotificationEventConfigurationLookupUrl}${secondaryUrl}`
    );
  }

  getNotificationLiquidVariablesConfigurationLookup(
    notificationModuleConfigurationId: string
  ): Observable<LiquidVariablesConfigurationLookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiNotificationLiquidVariablesConfigurationLookupUrl}?notificationModuleConfigurationId=${notificationModuleConfigurationId}`
    );
  }

  getCompanyProfileLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCompanyProfileLookupUrl}`
    );
  }

  getApprovalGroupLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiApprovalGroupLookupUrl}`
    );
  }

  getApprovalActionLookup(): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiApprovalActionLookupUrl}`
    );
  }

  getUserCompanylookUp(isCRMAdmin: boolean): Observable<CompanyLookupModel[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiCompanyProfileUrl}/userCompanylookUp?isCRMAdmin=${isCRMAdmin}`
    );
  }

  getReminderRepeaMasterLookup(
    type: string,
    isCustomIncluded: boolean = true
  ): Observable<LookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiReminderRepeatMasterUrl}?type=${type}&isRepeatWithoutCustom=${isCustomIncluded}`
    );
  }

  getWidgetLookup(): Observable<WidgetLookUpElement[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiWidgetUrl}`
    );
  }
}
