import { Component, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/core/service/language.service';
import {
  LeadGroupedDataSource,
  LeadGroupedList,
  LeadPaginated,
  Leads,
} from 'src/app/home/leads/models/leads.model';
import { LeadsSharedService } from 'src/app/home/leads/services/leads-shared.service';
import { LeadsService } from 'src/app/home/leads/services/leads.service';
import { Constants } from 'src/app/shared/data/constants';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { CommonChooseListSharedService } from 'src/app/shared/services/common-choose-list-shared.service';

@Component({
  selector: 'app-lead-list-modal',
  templateUrl: './lead-list-modal.component.html',
  styleUrls: ['./lead-list-modal.component.scss'],
})
export class LeadListModalComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  leadListSubject: Subscription;
  leadIdSubject: Subscription;
  leadListChangedSubject: Subscription;
  leadList: Leads[] = [];
  displayedColumns: string[] = [
    'Select',
    'LeadNo',
    'FirstName',
    'LastName',
    'Company',
    'Phone',
    'Email',
    'LeadStatus',
    'CreationDate',
  ];
  dataSources: LeadGroupedDataSource[];
  dataSource = new MatTableDataSource<Leads>(this.leadList);
  modalRef: BsModalRef;
  baseFilterVm: BaseFilterVm;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('template', { static: true }) template: any;
  isLoading: boolean = false;
  selectedLeadId: string = null;
  // pageSize = Constants.MAX_PAGE_SIZE;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageLength: number = 0;
  totalItems: number = 0;
  leadPaginated: LeadPaginated;
  leadGroupedList: LeadGroupedList[] = [];

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private paginatorIntl: MatPaginatorIntl,
    public helpersService: HelpersService,
    private modalService: BsModalService,
    private leadsSharedService: LeadsSharedService,
    private leadsService: LeadsService,
    private commonChooseListSharedService: CommonChooseListSharedService,
    private quickCreateSharedService: QuickCreateSharedService
  ) {
    this.leadListSubject = commonChooseListSharedService
      .getSubjectOpenLeadListModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
          this.getAllLeads();
        }
      });

    this.leadIdSubject = this.commonChooseListSharedService
      .getSubjectSelectedLeadId()
      .pipe(takeUntil(this.destroy))
      .subscribe((leadId: string) => {
        if (leadId != null) {
          this.selectedLeadId = leadId;
        }
      });

    this.leadListChangedSubject = this.commonChooseListSharedService
      .getSubjectLeadListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllLeads();
        }
      });

    this.leadListChangedSubject = this.quickCreateSharedService
      .getSubjectChooseLeadListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllLeads();
        }
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.languageService
      .getLanguageChangedSubject()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: string) => {
        if (flag != null) {
          this.setTranslations();
        }
      });
  }
  ngOnInit(): void {}

  setTranslations() {
    this.translateService
      .get(['MASTER_MENU.ITEMS_PER_PAGE'])
      .subscribe((translations) => {
        this.paginatorIntl.itemsPerPageLabel =
          translations['MASTER_MENU.ITEMS_PER_PAGE'];
      });
  }

  setFilterData() {
    this.baseFilterVm = {
      searchText: '',
      groupBy: '',
      sortBy: '',
      filters: [
        {
          condition: 'Equal',
          filterBy: 'ApprovalStatus',
          filterClause: 'AND',
          value: 'Approved',
          dataType: 'STRING',
          lookupValueList: [],
        },
      ],
      isDescending: false,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };
  }

  getAllLeads() {
    this.setFilterData();
    this.leadGroupedList = [];
    this.isLoading = true;
    this.dataSources = [];
    this.dataSource.data = [];

    this.leadsService
      .getLeadList(this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: LeadPaginated | LeadGroupedList[]) => {
          if ('totalItems' in res) {
            this.leadPaginated = res;
            this.leadList = res.items;
            if (Array.isArray(res.items)) {
              this.dataSource = new MatTableDataSource<Leads>(res.items);
            }
            this.totalItems = res.totalItems;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  createLead() {
    this.quickCreateSharedService.setSubjectQuickCreateLeadOpenModal('Open');
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getAllLeads();
  }

  ngOnDestroy(): void {
    // this.leadsSharedService.setSubjectOpenCampaignListModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonChooseListSharedService.setSubjectOpenLeadListModal(null);
  }

  onRadioButtonChange(event: Event): void {
    // event contains the selected value
    console.log('Selected option:', this.selectedLeadId);
  }

  onClickcChooseLead() {
    this.commonChooseListSharedService.setSubjectSelectedLeadId(
      this.selectedLeadId
    );
    this.modalRef.hide();
    this.commonChooseListSharedService.setSubjectOpenLeadListModal(null);
  }

  handleCancel() {
    this.modalRef.hide();
    this.selectedLeadId = null;
    this.commonChooseListSharedService.setSubjectOpenLeadListModal(null);
  }
}
