import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement, QuickAddCampaign } from 'src/app/shared/models/common-data.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { QuickCreateService } from 'src/app/shared/services/quick-create.service';
import { CommonSharedService } from '../../../services/common-shared.service'

@Component({
  selector: 'app-quick-add-campaign-modal',
  templateUrl: './quick-add-campaign-modal.component.html',
  styleUrls: ['./quick-add-campaign-modal.component.scss']
})
export class QuickAddCampaignModalComponent {

  @ViewChild('template', { static: true }) template: any;
  campaignAddSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  campaignForm: FormGroup;
  isLoading: boolean = false;
  campaignStatusList: LookUpElement[] = [];
  campaignTypeList: LookUpElement[] = [];
  campaignStatusSearch: string;
  campaignTypeSearch: string;
  quickAddCampaignReq: QuickAddCampaign;
  @Input() referenceId;
  @Input() module;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
    private helpersService: HelpersService,
    private lookupsService: LookupsService,
    private commonService: CommonService,
    private commonSharedService: CommonSharedService
  ){
    this.campaignAddSubject = commonSharedService
    .getSubjectQuickAddCampignModal()
    .pipe(takeUntil(this.destroy))
    .subscribe((sortByOpen: any) => {
      if(sortByOpen!= null && sortByOpen == 'Open') {
        this.showModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.commonSharedService.setSubjectQuickAddCampignModal(null);
    this.commonSharedService.setSubjectAssignCampaignsTableChanged(null);
    this.commonSharedService.setSubjectAccountAssignCampaignsTableChanged(null)
    this.commonSharedService.setSubjectContactAssignCampaignsTableChanged(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createCampaignForm();
    this.getLookupList();
  }

  getLookupList() {
    this.getCamapignStatusList();
    this.getCamapignTypeList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg'
    });
  }

  createCampaignForm() {
    this.campaignForm = this.fb.group({
      CampaignTypeId: [null],
      CampaignName: [''],
      CampaignStatusId: [null],
      StartDate: [null],
      EndDate: [null],
      ExpectedRevenue: [0]
    });
  }

  getCurrentDate() {
    return new Date();
  }

  cancelForm(){
    this.modalRef.hide();
    this.campaignForm.reset();
    this.commonSharedService.setSubjectAssignCampaignsTableChanged(null);
    this.commonSharedService.setSubjectContactAssignCampaignsTableChanged(null);
    this.commonSharedService.setSubjectAccountAssignCampaignsTableChanged(null)
    this.commonSharedService.setSubjectQuickAddCampignModal(null);
  }

  getCamapignStatusList() {
    this.lookupsService
      .getCampaignLookup('CampaignStatus')
      .subscribe((campaignStatusList: LookUpElement[]) => {
        this.campaignStatusList = campaignStatusList;
      });
  }

  getCamapignTypeList() {
    this.lookupsService
      .getCampaignLookup('CampaignType')
      .subscribe((campaignTypeList: LookUpElement[]) => {
        this.campaignTypeList = campaignTypeList;
      });
  }

  createCampaign(formData: any){
    this.setReqBody(formData);
    this.saveCampaignInDB();
  }

  setReqBody(formData: any) {
    this.quickAddCampaignReq = {
      campaignTypeId: formData.CampaignTypeId,
      campaignName: formData.CampaignName,
      campaignStatusId: formData.CampaignStatusId,
      startDate: formData.StartDate,
      endDate: formData.EndDate,
      expectedRevenue: formData.ExpectedRevenue ?? 0,
      referenceId: this.referenceId,
      module: this.module
    }
  }

  saveCampaignInDB() {
    this.isLoading = true;
    this.commonService
    .quickAddCampaign(this.quickAddCampaignReq)
    .subscribe((res: any) => {
      if(res) {
        this.cancelForm();
        this.helpersService.showSuccessToast("Assigned Successfully");
        this.commonSharedService.setSubjectQuickAddCampignModal(null);
        if(this.module == 'LEAD') {
          this.commonSharedService.setSubjectAssignCampaignsTableChanged(true);
        } else if(this.module == 'CONTACT') {
          this.commonSharedService.setSubjectContactAssignCampaignsTableChanged(true)
        } else if(this.module == 'ACCOUNT') {
          this.commonSharedService.setSubjectAccountAssignCampaignsTableChanged(true)
        }
        this.isLoading = false;
      }
    }, () => {
      this.isLoading = false;
    });
  }

}
