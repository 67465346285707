import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComposeEmail } from '../models/contact.model';

@Injectable({
  providedIn: 'root',
})
export class ContactSharedService {
  private subjectOpenCallsAddModal = new BehaviorSubject(null);
  private subjectCallLogTableChange = new BehaviorSubject(null);
  private subjectOpenMeetingAddModal = new BehaviorSubject(null);
  private subjectOpenMeetingTableChange = new BehaviorSubject(null);
  private subjectNumberOfMail = new BehaviorSubject(null);
  private subjectDraftTableChanged = new BehaviorSubject(null);
  private subjectNumberOfDraftMail = new BehaviorSubject(null);
  private subjectEmailTableChanged = new BehaviorSubject(null);
  private subjectOpenScheduleModal = new BehaviorSubject(null);
  private subjectCurrentContactMail = new BehaviorSubject(null);
  private subjectContactImportModal = new BehaviorSubject(null);
  private subjectNumberOfScheduleMail = new BehaviorSubject(null);
  private subjectScheduledTableChanged = new BehaviorSubject(null);
  private subjectRemoveEmailDraftModal = new BehaviorSubject(null);
  private subjectNumberOfCanceledMails = new BehaviorSubject(null);
  private subjectCancelEmailTableChanged = new BehaviorSubject(null);
  private subjectContactListTableChanged = new BehaviorSubject(null);
  private subjectOpenComposeEmailAddModal = new BehaviorSubject(null);
  private subjectOpenComposeEmailEditModal = new BehaviorSubject(null);

  constructor() {}

  //Contact View - open ativities - Calls
  setsubjectOpenMeetingCallsModal(string: string) {
    this.subjectOpenCallsAddModal.next(string);
  }
  getsubjectOpenMeetingCallsModal(): Observable<string> {
    return this.subjectOpenCallsAddModal.asObservable();
  }

  //Contact call log
  setSubjectCallLogTableChange(flag: boolean) {
    return this.subjectCallLogTableChange.next(flag);
  }

  getSubjectCallLogTableChange(): Observable<boolean> {
    return this.subjectCallLogTableChange.asObservable();
  }

  //Contact View - open ativities - Meeting
  setsubjectOpenMeetingAddModal(string: string) {
    this.subjectOpenMeetingAddModal.next(string);
  }
  getsubjectOpenMeetingAddModal(): Observable<any> {
    return this.subjectOpenMeetingAddModal.asObservable();
  }

  //Contact Open Activity Meeting List view
  getsubjectOpenMeetingTableChange(): Observable<any> {
    return this.subjectOpenMeetingTableChange.asObservable();
  }
  setsubjectOpenMeetingTableChange(flag: boolean) {
    this.subjectOpenMeetingTableChange.next(flag);
  }

  //Contact Compose Email
  setsubjectOpenComposeEmailAddModal(string: string) {
    this.subjectOpenComposeEmailAddModal.next(string);
  }

  getsubjectOpenComposeEmailAddModal(): Observable<any> {
    return this.subjectOpenComposeEmailAddModal.asObservable();
  }

  setSubjectEmailTableChanged(flag: boolean) {
    return this.subjectEmailTableChanged.next(flag);
  }

  getSubjectEmailTableChanged(): Observable<any> {
    return this.subjectEmailTableChanged.asObservable();
  }

  setSubjectDraftTableChanged(flag: boolean) {
    return this.subjectDraftTableChanged.next(flag);
  }

  getSubjectDraftTableChanged(): Observable<any> {
    return this.subjectDraftTableChanged.asObservable();
  }

  setsubjectOpenComposeEmailEditModal(string: string) {
    this.subjectOpenComposeEmailEditModal.next(string);
  }

  getsubjectOpenComposeEmailEditModal(): Observable<any> {
    return this.subjectOpenComposeEmailEditModal.asObservable();
  }

  setSubjectOpenScheduleModal(obj: ComposeEmail) {
    this.subjectOpenScheduleModal.next(obj);
  }

  getSubjectOpenScheduleModal(): Observable<any> {
    return this.subjectOpenScheduleModal.asObservable();
  }

  setSubjectScheduledTableChanged(flag: boolean) {
    return this.subjectScheduledTableChanged.next(flag);
  }

  getSubjectScheduledTableChanged(): Observable<any> {
    return this.subjectScheduledTableChanged.asObservable();
  }

  setSubjectNumberOfMails(number: number) {
    return this.subjectNumberOfMail.next(number);
  }

  getSubjectNumberOfMails(): Observable<any> {
    return this.subjectNumberOfMail.asObservable();
  }

  setSubjectNumberOfDraftMails(number: number) {
    return this.subjectNumberOfDraftMail.next(number);
  }

  getSubjectNumberOfDraftMails(): Observable<any> {
    return this.subjectNumberOfDraftMail.asObservable();
  }

  setSubjectNumberOfScheduleMails(number: number) {
    return this.subjectNumberOfScheduleMail.next(number);
  }

  getSubjectNumberOfScheduleMails(): Observable<any> {
    return this.subjectNumberOfScheduleMail.asObservable();
  }

  setSubjectCurrentContactMail(mail: string) {
    return this.subjectCurrentContactMail.next(mail);
  }

  getSubjectCurrentContactMail(): Observable<any> {
    return this.subjectCurrentContactMail.asObservable();
  }

  setSubjectRemoveEmailDraftModal(flag: boolean) {
    this.subjectRemoveEmailDraftModal.next(flag);
  }
  getSubjectRemoveEmailDraftModal(): Observable<any> {
    return this.subjectRemoveEmailDraftModal.asObservable();
  }

  setSubjectCancelEmailTableChanged(flag: boolean) {
    this.subjectCancelEmailTableChanged.next(flag);
  }
  getSubjectCancelEmailTableChanged(): Observable<any> {
    return this.subjectCancelEmailTableChanged.asObservable();
  }

  setSubjectNumberOfCanceledMails(count: number) {
    this.subjectNumberOfCanceledMails.next(count);
  }
  getSubjectNumberOfCanceledMails(): Observable<any> {
    return this.subjectNumberOfCanceledMails.asObservable();
  }

  setSubjectContactImportModal(str: string) {
    this.subjectContactImportModal.next(str);
  }
  getSubjectContactImportModal(): Observable<any> {
    return this.subjectContactImportModal.asObservable();
  }

  setSubjectContactListTableChanged(flag: boolean) {
    this.subjectContactListTableChanged.next(flag);
  }
  getSubjectContactListTableChanged(): Observable<any> {
    return this.subjectContactListTableChanged.asObservable();
  }

}
