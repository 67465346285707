import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentUpload } from '../models/document-upload.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadSharedService {
  private subjectDocumentAttachmentList = new BehaviorSubject(null);

  constructor() { }

  setsubjectDocumentAttachmentList(attachmentArray: DocumentUpload[]) {
    this.subjectDocumentAttachmentList.next(attachmentArray);
  }

  getsubjectDocumentAttachmentList(): Observable<DocumentUpload[]> {
    return this.subjectDocumentAttachmentList.asObservable();
  }
}
