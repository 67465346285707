<ng-template #template>
  <h5 class="modal-title modal-container quick-create-modal-rtl">{{'COMMON.QUICK_CREATE_LEAD' | translate}}</h5>
  <div class="container modal-container">
    <form [formGroup]="leadForm">
      <div class="row quick-create-modal-rtl">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.LEAD_OWNER' | translate }}</mat-label>
            <mat-select formControlName="LeadOwner" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="userInformationSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                  ></ngx-mat-select-search
                >
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let user of userInformationList
                    | filter : userInformationSearch
                "
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.COMPANY' | translate }}</mat-label>
            <input type="text" matInput formControlName="Campany" required/>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.FIRST_NAME' | translate }}</mat-label>
            <input type="text" matInput formControlName="FirstName" required/>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'COMMON.LAST_NAME' | translate }}</mat-label>
            <input type="text" matInput formControlName="LastName" required />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.LEAD_NAME' | translate }}</mat-label>
            <input type="text" matInput formControlName="LeadName" required />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.EMAIL' | translate }}</mat-label>
            <input type="text" matInput formControlName="Email" required />
            <mat-error *ngIf="leadForm.get('Email').hasError('pattern')">
              Please enter a valid email address.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label> {{ 'LEADS_MENU.PHONE' | translate }}</mat-label>
            <input type="text" matInput formControlName="Phone"/>
            <mat-error *ngIf="leadForm.get('Phone').hasError('pattern')">
              Please enter a valid phone number.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.LEAD_SOURCE' | translate }}</mat-label>
            <mat-select formControlName="LeadSourceId" required>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="{{ 'COMMON.SEARCH' | translate }}" noEntriesFoundLabel="{{
                            'COMMON.NO_RESULTS_FOUND' | translate
                          }}" [(ngModel)]="leadSourceSearch" [ngModelOptions]="{ standalone: true }">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option class="form-select-option" *ngFor="
                          let leadsSource of leadsSourceLookup | filter : leadSourceSearch
                        " [value]="leadsSource.id">{{ leadsSource.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'LEADS_MENU.LEAD_STATUS' | translate }}</mat-label>
            <mat-select formControlName="LeadStatusId" required>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="{{ 'COMMON.SEARCH' | translate }}" noEntriesFoundLabel="{{
                          'COMMON.NO_RESULTS_FOUND' | translate
                        }}" [(ngModel)]="leadStatusSearch" [ngModelOptions]="{ standalone: true }">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option class="form-select-option" *ngFor="
                        let leadStatus of leadsStatusLookup | filter : leadStatusSearch
                      " [value]="leadStatus.id">{{ leadStatus.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </form>
  </div>
  &nbsp;&nbsp;&nbsp;
  <div class="row quick-create-modal-rtl ms-5">
    <div class="col-md-12 d-flex flex-row-reverse">
      <button mat-flat-button color="primary" class="flat-info-btn" *ngIf="isLoading">
        <mat-spinner diameter="20"></mat-spinner>
      </button>&nbsp;&nbsp;&nbsp;
      <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" [disabled]="!leadForm.valid"
        (click)="createLead(leadForm.value)" *ngIf="!isLoading">
        {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon>
      </button>&nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="cancelForm()">
        {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
