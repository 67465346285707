import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private helperService: HelpersService,
    private authenticationService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const error = err?.error?.message || err?.statusText;

        if (err.status === 401) {
          this.helperService.showErrorToast(err.error?.error_description);
          setTimeout(() => {
            this.authenticationService.logout();
            location.reload();
          }, 3000);
        } else {
          if ('errors' in err.error) {
            this.helperService.showErrorToast(err.error?.title);

            if (err.error.status == 400) {
              var validationerrors = err.error.errors;
              var errmsgs: string[] = [];
              Object.keys(validationerrors).forEach((errfield) => {
                if (validationerrors[errfield].length > 0) {
                  errmsgs.push(validationerrors[errfield][0] ?? '' + '<br/>');
                }
              });

              const concatenatedErrors = errmsgs.join('<br>'); // Concatenate errors with line breaks
              Swal.fire({
                icon: 'error',
                title: 'Errors',
                html: concatenatedErrors, // Use html option to render line breaks
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000, // Adjust the timer as needed
              });
            }
          } else {
            if (this.router.url.includes('signin') && err.status === 400) {
              this.helperService.showErrorToast(err.error?.error_description);
            } else {
              this.helperService.showErrorToast(error);
            }
          }
        }

        return throwError(error);
      })
    );
  }
}
