import { Component, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { DocumentUpload } from 'src/app/shared/models/document-upload.model';
import { DealEdit } from 'src/app/home/deals/deal-edit/models/deal-edit.model';
import { DealAssignmentService } from 'src/app/shared/services/deal-assignment.service';
import { DocumentUploadSharedService } from 'src/app/shared/services/document-upload-shared.service';
import { DealAssignmentSharedService } from 'src/app/shared/services/deal-assignment-shared.service';
import {
  ExternalConfigurationLookUpElement,
  LookUpElement,
} from 'src/app/shared/models/common-data.model';

@Component({
  selector: 'app-edit-deal-assignment',
  templateUrl: './edit-deal-assignment.component.html',
  styleUrls: ['./edit-deal-assignment.component.sass'],
})
export class EditDealAssignmentComponent {
  dealId: string;
  dealReq: DealEdit;
  dealObj: DealEdit;
  userSearch: string;
  dealForm: FormGroup;
  minDate = new Date();
  modalRef: BsModalRef;
  contactSearch: string;
  accountSearch: string;
  dealTypeSearch: string;
  campaignSearch: string;
  dealStageSearch: string;
  mediaListSearch: string;
  closingDate = new Date();
  leadSourceSearch: string;
  isLoading: boolean = false;
  emailExists: boolean = false;
  masterProgrammeSearch: string;
  bachelorProgrammeSearch: string;
  usersList: LookUpElement[] = [];
  contactList: LookUpElement[] = [];
  accountList: LookUpElement[] = [];
  campaignList: LookUpElement[] = [];
  editDealsOpenSubject: Subscription;
  dealTypeList: LookUpElement[] = [];
  dealStageList: LookUpElement[] = [];
  leadSourceList: LookUpElement[] = [];
  attachmentList: DocumentUpload[] = [];
  @ViewChild('template', { static: true }) template: any;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  programmeLevelsList: ExternalConfigurationLookUpElement[];
  masterProgrammeList: ExternalConfigurationLookUpElement[];
  bachelorProgrammeList: ExternalConfigurationLookUpElement[];
  howDidYouHearAboutUsList: ExternalConfigurationLookUpElement[];
  applicantClassificationList: ExternalConfigurationLookUpElement[];

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    public helpersService: HelpersService,
    private lookupsService: LookupsService,
    private dealAssignmentService: DealAssignmentService,
    private dealAssignmentSharedService: DealAssignmentSharedService,
    private documentUploadSharedService: DocumentUploadSharedService
  ) {
    this.editDealsOpenSubject = dealAssignmentSharedService
      .getSubjectEditDealsOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((dealId: any) => {
        if (dealId != null) {
          this.dealId = dealId;
          this.getDealById(dealId);
          this.showModal();
        }
      });

    this.documentUploadSharedService
      .getsubjectDocumentAttachmentList()
      .subscribe(
        (list: DocumentUpload[]) => {
          if (list != null) {
            this.attachmentList = list;
          }
        },
        (err) => {
          this.attachmentList = [];
        }
      );
  }

  ngOnInit() {
    this.createDealForm();
    this.getLookupsList();
  }

  handleCancel() {
    this.modalRef.hide();
    this.dealAssignmentSharedService.setSubjectEditDealsOpenModal(null);
  }

  ngOnDestroy(): void {
    this.modalRef?.hide();
    this.destroy.next(null);
    this.dealAssignmentSharedService.setSubjectEditDealsOpenModal(null);
  }

  backClick() {
    this.modalRef?.hide();
    this.dealForm.reset();
  }

  cancelForm() {
    this.modalRef?.hide();
    this.dealForm.reset();
    this.dealForm.patchValue({ isActive: true });
    this.dealAssignmentSharedService.setSubjectEditDealsOpenModal(null);
    this.dealAssignmentSharedService.setSubjectAssignExistingDealsTableChanged(
      null
    );
  }

  getLookupsList() {
    this.getLeadSourceList();
    this.getDealStageList();
    this.getDealTypeList();
    this.getAccountLookupList();
    this.getContactLookupList();
    this.getCampaignLookupList();
    this.getApplicantClassificationList();
    this.getBachelorProgrammeList();
    this.getHowDidYouHearAboutUsList();
    this.getMasterProgrammeList();
    this.getProgrammeLevelsList();
    this.getUsersList();
  }

  createDealForm() {
    this.dealForm = this.fb.group({
      DealNo: [''],
      DealOwnerId: [''],
      DealName: [''],
      AccountId: [null],
      DealTypeId: [null],
      NextStep: [''],
      LeadSourceId: [null],
      ContactId: [null],
      Description: [''],
      Amount: [0],
      ClosingDate: [''],
      DealStageId: [null],
      Probability: [0],
      ExpectedRevenue: [0],
      CampaignSourceId: [null],
      ApplicantClassificationId: [null],
      ProgrammeLevelId: [null],
      BachelorProgrammeId: [null],
      MasterProgrammeId: [null],
      HowDidHear: [null],
      Email: [''],
    });
  }

  editDeal(fromData: any, approvalState: string) {
    this.setDealReqBody(fromData, approvalState);
    this.editDealInDb();
  }

  editDealInDb() {
    this.isLoading = true;
    this.dealAssignmentService.editDeal(this.dealReq).subscribe(
      (res: any) => {
        if (res) {
          this.isLoading = false;
          this.helpersService.showSuccessToast('Updated Successfully');
          this.dealForm.reset();
          this.dealAssignmentSharedService.setSubjectAssignExistingDealsTableChanged(
            true
          );
          this.cancelForm();
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  setDealReqBody(formData: any, status: string) {
    this.dealReq = {
      id: this.dealId,
      dealOwnerId: formData.DealOwnerId,
      dealName: formData.DealName,
      dealNumber: formData.DealNo,
      accountId: formData.AccountId,
      dealTypeId: formData.DealTypeId,
      nextStep: formData.NextStep,
      leadSourceId: formData.LeadSourceId,
      contactId: formData.ContactId,
      description: formData.Description,
      amount: formData.Amount,
      closingDate: this.helpersService.setDateForDb(formData.ClosingDate),
      dealStageId: formData.DealStageId,
      probability: formData.Probability,
      expectedRevenue: formData.ExpectedRevenue,
      approvalState: status,
      attachments: this.attachmentList,
      campaignSourceId: formData.CampaignSourceId,
      contactRoleId: null,
      applicantClassificationId: formData.ApplicantClassificationId,
      programmeLevelId: formData.ProgrammeLevelId,
      bachelorProgrammeId: formData.BachelorProgrammeId,
      masterProgrammeId: formData.MasterProgrammeId,
      connectedWayId: formData.HowDidHear,
      email: formData.Email,
    };
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  getDealById(id: string) {
    this.dealAssignmentService.getDealById(id).subscribe((res: any) => {
      if (res) {
        this.dealObj = res;
        this.mapFormData();
      }
    });
  }

  mapFormData() {
    this.dealForm = this.fb.group({
      DealNo: [this.dealObj.dealNumber],
      DealOwnerId: [this.dealObj.dealOwnerId],
      DealName: [this.dealObj.dealName],
      AccountId: [this.dealObj.accountId],
      DealTypeId: [this.dealObj.dealTypeId],
      NextStep: [this.dealObj.nextStep],
      LeadSourceId: [this.dealObj.leadSourceId],
      ContactId: [this.dealObj.contactId],
      Description: [this.dealObj.description],
      Amount: [this.dealObj.amount],
      ClosingDate: [
        this.helpersService.getDateFromDbFormatted(this.dealObj.closingDate),
      ],
      DealStageId: [this.dealObj.dealStageId],
      Probability: [this.dealObj.probability],
      ExpectedRevenue: [this.dealObj.expectedRevenue],
      CampaignSourceId: [this.dealObj.campaignSourceId],
      ApplicantClassificationId: [this.dealObj.applicantClassificationId],
      ProgrammeLevelId: [this.dealObj.programmeLevelId],
      BachelorProgrammeId: [this.dealObj.bachelorProgrammeId],
      MasterProgrammeId: [this.dealObj.masterProgrammeId],
      HowDidHear: [this.dealObj.connectedWayId],
      Email: [
        this.dealObj.email,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    });
    this.documentUploadSharedService.setsubjectDocumentAttachmentList(
      this.dealObj?.attachments
    );
    this.closingDate = new Date(this.dealObj.closingDate);
  }

  getDealTypeList() {
    this.lookupsService
      .getDealMasterLookup('DealType')
      .subscribe((dealTypeList: LookUpElement[]) => {
        this.dealTypeList = dealTypeList;
      });
  }

  getLeadSourceList() {
    this.lookupsService
      .getLeadMasterLookup('LeadSource')
      .subscribe((leadSourceList: LookUpElement[]) => {
        this.leadSourceList = leadSourceList;
      });
  }

  getDealStageList() {
    this.lookupsService
      .getDealMasterLookup('DealStage')
      .subscribe((dealStageList: LookUpElement[]) => {
        this.dealStageList = dealStageList;
      });
  }

  getContactLookupList() {
    this.lookupsService
      .getContactLookup()
      .subscribe((contactList: LookUpElement[]) => {
        this.contactList = contactList;
      });
  }

  getAccountLookupList() {
    this.lookupsService
      .getAccountLookup()
      .subscribe((accountList: LookUpElement[]) => {
        this.accountList = accountList;
      });
  }

  getCampaignLookupList() {
    this.lookupsService
      .getCampaignListLookup()
      .subscribe((campaignList: LookUpElement[]) => {
        this.campaignList = campaignList;
      });
  }

  getProgrammeLevelsList() {
    this.lookupsService
      .getExternalConfigurationLookup('Programme Levels')
      .subscribe((lookUpElement: ExternalConfigurationLookUpElement[]) => {
        this.programmeLevelsList = lookUpElement;
      });
  }

  getApplicantClassificationList() {
    this.lookupsService
      .getExternalConfigurationLookup('Applicant Classification')
      .subscribe((lookUpElement: ExternalConfigurationLookUpElement[]) => {
        this.applicantClassificationList = lookUpElement;
      });
  }
  getHowDidYouHearAboutUsList() {
    this.lookupsService
      .getExternalConfigurationLookup('How Did You Hear About Us')
      .subscribe((lookUpElement: ExternalConfigurationLookUpElement[]) => {
        this.howDidYouHearAboutUsList = lookUpElement;
      });
  }
  getBachelorProgrammeList() {
    this.lookupsService
      .getExternalConfigurationLookup('Bachelor Programme')
      .subscribe((lookUpElement: ExternalConfigurationLookUpElement[]) => {
        this.bachelorProgrammeList = lookUpElement;
      });
  }
  getMasterProgrammeList() {
    this.lookupsService
      .getExternalConfigurationLookup('Master Programme')
      .subscribe((lookUpElement: ExternalConfigurationLookUpElement[]) => {
        this.masterProgrammeList = lookUpElement;
      });
  }

  getUsersList() {
    this.lookupsService
      .getUserInformationLookUp()
      .pipe(takeUntil(this.destroy))
      .subscribe((usersList: LookUpElement[]) => {
        this.usersList = usersList;
      });
  }

  getCurrentDate() {
    return new Date();
  }

  emailFocusOut(event: any) {
    let email = event?.target.value.trim();
    this.dealAssignmentService.isDealEmailExists(this.dealId, email).subscribe(
      (res: any) => {
        this.emailExists = res;
      },
      (err) => {}
    );
  }

  expiryDateChanged(event: any) {
    this.closingDate = this.minDate;
  }
}
