import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, takeUntil, ReplaySubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { Constants } from 'src/app/shared/data/constants';
import {
  AccountGroupedDataSource,
  AccountGroupedList,
  AccountList,
  AccountPaginated,
} from 'src/app/home/accounts/account-list/models/account-list.model';
import { MatTableDataSource } from '@angular/material/table';
import { AccountListService } from 'src/app/home/accounts/account-list/services/account-list.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { CommonChooseListSharedService } from 'src/app/shared/services/common-choose-list-shared.service';

@Component({
  selector: 'app-account-list-modal',
  templateUrl: './account-list-modal.component.html',
  styleUrls: ['./account-list-modal.component.scss'],
})
export class AccountListModalComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  accountListSubject: Subscription;
  accountIdSubject: Subscription;
  accountListChangedSubject: Subscription;
  accountList: AccountList[] = [];
  selectedAccountId: string = null;
  modalRef: BsModalRef;
  baseFilterVm: BaseFilterVm;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('template', { static: true }) template: any;
  isLoading: boolean = false;
  pageSize = 10;
  pageIndex: number = 0;
  pageLength: number = 0;
  totalItems: number = 0;
  accountPaginated: AccountPaginated;
  accountGroupedList: AccountGroupedList[] = [];
  dataSources: AccountGroupedDataSource[];
  dataSource = new MatTableDataSource<AccountList>(this.accountList);
  displayedColumns: string[] = [
    'Select',
    'AccountName',
    'Phone',
    'Website',
    'AccountOwner',
  ];

  constructor(
    private translateService: TranslateService,
    private commonChooseListSharedService: CommonChooseListSharedService,
    private paginatorIntl: MatPaginatorIntl,
    private modalService: BsModalService,
    private accountsService: AccountListService,
    private quickCreateSharedService: QuickCreateSharedService,
    private languageService: LanguageService
  ) {
    this.accountListSubject = commonChooseListSharedService
      .getSubjectOpenAccountListModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
          this.getAllAccounts();
        }
      });

    this.accountIdSubject = this.commonChooseListSharedService
      .getSubjectSelectedAccountId()
      .pipe(takeUntil(this.destroy))
      .subscribe((accountId: string) => {
        if (accountId != null) {
          this.selectedAccountId = accountId;
        }
      });

    this.accountListChangedSubject = this.commonChooseListSharedService
      .getSubjectAccountListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllAccounts();
        }
      });

    this.accountListChangedSubject = this.quickCreateSharedService
      .getSubjectChooseAccountListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllAccounts();
        }
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.languageService
      .getLanguageChangedSubject()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: string) => {
        if (flag != null) {
          this.setTranslations();
        }
      });
  }

  setFilterData() {
    this.baseFilterVm = {
      searchText: '',
      groupBy: '',
      sortBy: '',
      filters: [
        {
          condition: 'Equal',
          filterBy: 'ApprovalStatus',
          filterClause: 'AND',
          value: 'Approved',
          dataType: 'STRING',
          lookupValueList: [],
        },
      ],
      isDescending: false,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };
  }

  setTranslations() {
    this.translateService
      .get(['MASTER_MENU.ITEMS_PER_PAGE'])
      .subscribe((translations) => {
        this.paginatorIntl.itemsPerPageLabel =
          translations['MASTER_MENU.ITEMS_PER_PAGE'];
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  getAllAccounts() {
    this.setFilterData();
    this.accountGroupedList = [];
    this.isLoading = true;
    this.dataSources = [];
    this.dataSource.data = [];

    this.accountsService
      .getAllAccounts(this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: AccountPaginated | AccountGroupedList[]) => {
          if ('totalItems' in res) {
            this.accountPaginated = res;
            this.accountList = res.items;
            if (Array.isArray(res.items)) {
              this.dataSource = new MatTableDataSource<AccountList>(res.items);
            }
            this.totalItems = res.totalItems;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  createAccount() {
    this.quickCreateSharedService.setSubjectQuickCreateAccountOpenModal('Open');
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getAllAccounts();
  }

  onClickcChooseAccount() {
    this.commonChooseListSharedService.setSubjectSelectedAccountId(
      this.selectedAccountId
    );
    this.modalRef.hide();
    this.commonChooseListSharedService.setSubjectOpenAccountListModal(null);
  }

  handleCancel() {
    this.modalRef.hide();
    this.selectedAccountId = null;
    this.commonChooseListSharedService.setSubjectOpenAccountListModal(null);
  }

  ngOnDestroy(): void {
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonChooseListSharedService.setSubjectOpenAccountListModal(null);
  }
}
