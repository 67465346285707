import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData } from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import {
  BsModalRef,
  BsModalService,
} from '../../../../../../node_modules/ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-sorting',
  templateUrl: './filter-sorting.component.html',
  styleUrls: ['./filter-sorting.component.scss'],
})
export class FilterSortingComponent implements OnDestroy {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];
  @Input() filterForm: FormGroup;
  sortingSearchText: string;

  /**
   *resetFilter
   */
  constructor(
    private modalService: BsModalService,
    private filterSharedService: FilterSharedService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.filterDataSubject = filterSharedService
      .getsubjectFilterData()
      .pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;
          this.coloumnsData = filterData.ColumnsData;
        }
      });

    this.filterDataSubject = filterSharedService
      .getsubjectSortByOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal();
        }
      });
    this.filterDataSubject = filterSharedService
      .getsubjectFilterReset()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {
          this.resetFilter(true);
        }
      });
  }

  ngOnInit() {
    this.initializeForm();
    this.mapColoumns();
  }

  get getSortArrayValues(): FormArray {
    return this.filterForm?.controls['SortByArr'] as FormArray;
  }

  initializeForm() {
    // this.filterForm?.addControl("sortBy", new FormControl());

    this.filterForm?.addControl('SortByArr', this.fb.array([]));

  }
  private showModal() {
    // this.offerForm.get("operationalHours").setValue(this.fb.array([]));
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg',
    });
  }

  newFilterValue(val): FormGroup {
    return this.fb.group({
      SortType: ['ASC'],
      Sortby: [val.data],
      IsActive: false,
      Translate: [val.translate],
    });
  }

  searchChange(event: any) {
    this.getSortArrayValues?.clear();

    const filterValue = event.target.value.toLowerCase();
    var filterColoumnsData = this.filterData.ColumnsData.filter((option) =>
      // option.translate.toLowerCase().includes(filterValue)
      this.translateService.instant(option.translate).toLowerCase().includes(filterValue)

    );
    for (let i = 0; i < filterColoumnsData.length; i++) {
      const element = filterColoumnsData[i];

      if (element.allowFilter)
        this.getSortArrayValues?.push(this.newFilterValue(element));
    }
  }

  resetFilter(isClearFromMainFilterMenu = false) {
    this.sortingSearchText = '';
    this.getSortArrayValues?.clear();
    this.mapColoumns();

    this.filterForm?.patchValue({
      sortBy: '',
      sortType: false,
      SortingSearchText: ''
    });
    if(!isClearFromMainFilterMenu) {
      this.filterSharedService.setsubjectApplyFilter(true);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.getSortArrayValues.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  mapColoumns() {
    for (let i = 0; i < this.coloumnsData?.length; i++) {
      const element = this.coloumnsData[i];

      if (element.allowFilter)
        this.getSortArrayValues?.push(this.newFilterValue(element));
    }
  }

  applyFilter() {
    this.filterSharedService.setsubjectApplyFilter(true);
  }

  ngOnDestroy() {
    this.filterSharedService.setsubjectSortByOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }
}
