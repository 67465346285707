import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordSharedService {

  constructor() { }
  private subjectOpenChangePasswordModal = new BehaviorSubject(null);

  setSubjectOpenChangePasswordModal(string: string) {
    this.subjectOpenChangePasswordModal.next(string);
  }

  getSubjectOpenChangePasswordModal(): Observable<string> {
    return this.subjectOpenChangePasswordModal.asObservable();
  }
}
