<ng-template #template>
  <div class="row container">
    <div class="col-md-6">
      <h5 class="modal-title pull-left"><b>Choose Campaign</b></h5>
    </div>
    <div class="col-md-6 d-flex flex-row-reverse mt-4">
        <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="createCampaign()">
          New Campaign<mat-icon>add</mat-icon>
        </button>
    </div>
  </div>
  <div class="modal-body">
    <app-loader *ngIf="isLoading"></app-loader>
      <div class="row mt-2">
          <div class="col-md-12 table-container">
            <table mat-table [dataSource]="dataSource" class="fleet-table">
            <mat-radio-group [(ngModel)]="selectedCampaignId" (change)="onRadioButtonChange($event)">
              <ng-container matColumnDef="Select">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <mat-radio-button [value]="element.id"></mat-radio-button>
                </td>
              </ng-container>
              <ng-container matColumnDef="CampaignName">
                <th mat-header-cell *matHeaderCellDef>{{'CAMPAIGN_MENU.CAMPAIGN_NAME' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.campaignName}} </td>
              </ng-container>
              <ng-container matColumnDef="Type">
                  <th mat-header-cell *matHeaderCellDef>{{'CAMPAIGN_MENU.TYPE' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.campaignType}} </td>
                </ng-container>
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef>{{'CAMPAIGN_MENU.STATUS' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element?.campaignStatus}} </td>
              </ng-container>
              <ng-container matColumnDef="StartDate">
                <th mat-header-cell *matHeaderCellDef>{{'CAMPAIGN_MENU.START_DATE' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.startDate">
                    {{
                      helpersService.getDateFromDb(element.startDate)?.format("DD/MM/YYYY hh:mm A")
                      }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="EndDate">
                <th mat-header-cell *matHeaderCellDef> {{'CAMPAIGN_MENU.END_DATE' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.endDate">
                    {{helpersService.getDateFromDb(element.endDate)?.format("DD/MM/YYYY hh:mm A")}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="CampaignOwner">
                <th mat-header-cell *matHeaderCellDef> {{'CAMPAIGN_MENU.CAMPAIGN_OWNER' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <!-- CRM User -->
                  {{element?.campaignOwner}}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
              </mat-radio-group>

            </table>
              <div>

              </div>
              <mat-paginator
              class="customPaginator"
              [pageSizeOptions]="[5, 10]"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [length]="totalItems"
              (page)="onPageChange($event)"
              showFirstLastButtons
              aria-label="Select page of periodic elements"
            >
            </mat-paginator>
          </div>
      </div>
  </div>
  <div class="modal-footer pe-4">
  <button mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="handleCancel()">
      {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
  </button>
  <button mat-flat-button color="primary" class="flat-info-btn" *ngIf="isLoading">
    <mat-spinner diameter="20"></mat-spinner>
  </button>&nbsp;&nbsp;
  <button mat-flat-button
  color="primary"
  class="flat-info-btn btn-text-size"
  (click)="chooseCampaign()"
  [disabled]="!selectedCampaignId"
  >
  {{ 'COMMON.SELECT' | translate }}<mat-icon>check_box</mat-icon>
  </button>
  </div>
</ng-template>

<app-quick-create-campaign-modal></app-quick-create-campaign-modal>
