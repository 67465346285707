<ng-template #template>
  <div class="row container">
    <div class="col-md-6">
      <h5 class="modal-title pull-left"><b>Choose Account</b></h5>
    </div>
    <div class="col-md-6 d-flex flex-row-reverse mt-4">
      <button
          mat-flat-button
          color="primary"
          class="flat-info-btn btn-text-size"
          (click)="createAccount()"
        >
          New Account<mat-icon>add</mat-icon>
        </button>
    </div>
  </div>
  <div class="modal-body">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row mt-2">
      <div class="col-md-12 table-container">
        <table mat-table [dataSource]="dataSource" class="fleet-table">
          <mat-radio-group
            [(ngModel)]="selectedAccountId"
          >
            <ng-container matColumnDef="Select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-radio-button [value]="element.id"></mat-radio-button>
              </td>
            </ng-container>
            <ng-container matColumnDef="AccountName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'LEADS_MENU.ACCOUNT_NAME' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.accountName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Phone">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'COMMON.PHONE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="Website">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'COMMON.WEBSITE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element?.website }}</td>
            </ng-container>
            <ng-container matColumnDef="AccountOwner">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'ACCOUNTS_MENU.ACCOUNT_OWNER' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element?.accountOwner }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </mat-radio-group>
        </table>

        <mat-paginator
          class="customPaginator"
          [pageSizeOptions]="[5, 10]"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [length]="totalItems"
          (page)="onPageChange($event)"
          showFirstLastButtons
          aria-label="Select page of periodic elements"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="modal-footer pe-4">
    <button
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="handleCancel()"
    >
      {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn"
      *ngIf="isLoading"
    >
      <mat-spinner diameter="20"></mat-spinner>
    </button>&nbsp;&nbsp;
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="onClickcChooseAccount()"
      [disabled]="!selectedAccountId"
    >
    {{ 'COMMON.SELECT' | translate }}<mat-icon>check_box</mat-icon>
    </button>
  </div>
</ng-template>

<app-quick-create-account-modal></app-quick-create-account-modal>
