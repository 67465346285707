import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QucikCreateDeal } from 'src/app/shared/models/quick-create.model';
import { QuickCreateService } from 'src/app/shared/services/quick-create.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { DealAssignmentSharedService } from 'src/app/shared/services/deal-assignment-shared.service';
import { ContactViewService } from 'src/app/home/contacts/contact-view/services/contact-view.service';

@Component({
  selector: 'app-quick-create-deal-modal',
  templateUrl: './quick-create-deal-modal.component.html',
  styleUrls: ['./quick-create-deal-modal.component.scss'],
})
export class QuickCreateDealModalComponent {
  userSearch: string;
  dealForm: FormGroup;
  modalRef: BsModalRef;
  accountSearch: string;
  @Input() module: null;
  dealStageSearch: string;
  defaultAccountId = null;
  @Input() referenceId: null;
  isLoading: boolean = false;
  dealCreateSubject: Subscription;
  usersList: LookUpElement[] = [];
  accountList: LookUpElement[] = [];
  dealStageList: LookUpElement[] = [];
  qucikCreateDealReq: QucikCreateDeal;
  @ViewChild('template', { static: true }) template: any;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private lookupsService: LookupsService,
    private helpersService: HelpersService,
    private translateService: TranslateService,
    private quickCreateService: QuickCreateService,
    private contactViewService: ContactViewService,
    private quickCreateSharedService: QuickCreateSharedService,
    private dealAssignmentSharedService: DealAssignmentSharedService
  ) {
    this.dealCreateSubject = quickCreateSharedService
      .getSubjectQuickCreateDealOpenModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.quickCreateSharedService.setSubjectQuickCreateDealOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createDealForm();
    this.getLookupList();
    this.getDefaultAccountId();
  }

  getLookupList() {
    this.getAccountLookupList();
    this.getDealStageList();
    this.getUsersList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg',
    });
    if (this.referenceId && this.module == 'ACCOUNT') {
      this.dealForm?.get('AccountId')?.setValue(this.referenceId);
      this.dealForm?.get('AccountId')?.disable();
    }
    if (this.module == 'CONTACT' && this.defaultAccountId) {
      this.dealForm?.get('AccountId')?.setValue(this.defaultAccountId);
    }
  }

  createDealForm() {
    this.dealForm = this.fb.group({
      DealOwnerId: [''],
      Amount: [0],
      DealName: [''],
      ClosingDate: [''],
      AccountId: [this.getAccountId()],
      DealStageId: [null],
      Email: [
        '',
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    });
    if (this.referenceId && this.module == 'ACCOUNT') {
      this.dealForm?.get('AccountId')?.disable();
    }
  }

  private getAccountId(): string | null {
    if (this.referenceId && this.module == 'ACCOUNT') {
      return this.referenceId;
    }
    if (this.defaultAccountId && this.module == 'CONTACT') {
      return this.defaultAccountId;
    }
    return null;
  }

  cancelForm() {
    this.modalRef.hide();
    this.dealForm.reset();
  }

  getDealStageList() {
    this.lookupsService
      .getDealMasterLookup('DealStage')
      .subscribe((dealStageList: LookUpElement[]) => {
        this.dealStageList = dealStageList;
      });
  }

  getAccountLookupList() {
    this.lookupsService
      .getAccountLookup()
      .subscribe((accountList: LookUpElement[]) => {
        this.accountList = accountList;
      });
  }

  getUsersList() {
    this.lookupsService
      .getUserInformationLookUp()
      .pipe(takeUntil(this.destroy))
      .subscribe((usersList: LookUpElement[]) => {
        this.usersList = usersList;
      });
  }

  createDeal(formData: any) {
    this.setReqBody(formData);
    this.saveDealInDB();
  }

  getDefaultAccountId() {
    if (
      this.referenceId != null &&
      this.module != null &&
      this.module == 'CONTACT'
    ) {
      this.contactViewService
      .getContactById(this.referenceId)
      .subscribe((res: any) => {
        if (res?.accountId) {
            this.defaultAccountId = res.accountId;
          }
        });
    }
  }

  setReqBody(formData: any) {
    this.qucikCreateDealReq = {
      dealOwnerId: formData.DealOwnerId,
      amount: formData.Amount,
      dealName: formData.DealName,
      closingDate: formData.ClosingDate,
      dealStageId: formData.DealStageId,
      email: formData.Email,
      accountId: this.getAccountId() ?? formData.AccountId,
      contactId:
        this.referenceId && this.module == 'CONTACT' ? this.referenceId : null,
      campaignSourceId:
        this.referenceId && this.module == 'CAMPAIGN' ? this.referenceId : null,
    };
  }

  saveDealInDB() {
    this.isLoading = true;
    this.quickCreateService.quickCreateDeal(this.qucikCreateDealReq).subscribe(
      (res: any) => {
        if (res) {
          this.cancelForm();
          this.helpersService.showSuccessToast('Created Successfully');
          this.quickCreateSharedService.setSubjectQuickCreateDealOpenModal(
            null
          );
          this.quickCreateSharedService.setSubjectChooseDealListTableChanged(
            true
          );
          this.dealAssignmentSharedService.setSubjectAssignExistingDealsTableChanged(
            true
          );
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getCurrentDate() {
    return new Date();
  }
}
