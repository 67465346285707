import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseFilterVm } from '../models/filter-data.model';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import {
  CampaignAssignment,
  MeetingParticipantTypeList,
  MeetingParticipantType,
  QuickAddCampaign,
  AuditEvent,
  Note,
  AuditEventPaginated,
} from '../models/common-data.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private apiCampaignUrl = 'api/Campaign';
  private apiCampaignAssignmentUrl = 'api/CampaignAssignment';
  private apiMeetingUrl = 'api/Meeting';
  private apiNoteUrl = 'api/Note';
  private apiAuditEventUrl = 'api/AuditEvent';

  constructor(public baseDataService: BaseDataService) {}

  getAssignedCampaignDetailList(
    baseFilterVm: BaseFilterVm,
    referenceId: string
  ): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/getAssignedCampaignDetailList?referenceId=${referenceId}`,
      baseFilterVm
    );
  }

  getAssignedOrUnassignedCampaigns(
    baseFilterVm: BaseFilterVm,
    referenceId: string,
    isAssigned: boolean
  ): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/getAssignedCampaigns?referenceId=${referenceId}&IsAssigned=${isAssigned}`,
      baseFilterVm
    );
  }

  assignCampaigns(campaignAssignment: CampaignAssignment): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignAssignmentUrl}/campaignAssignmentListAdd`,
      campaignAssignment
    );
  }

  removeAssignCampaign(campaignAssignmentId: string): Observable<any> {
    return this.baseDataService.makeDeleteCall(
      `${this.apiCampaignAssignmentUrl}/${campaignAssignmentId}`
    );
  }

  quickAddCampaign(quickAddCampaign: QuickAddCampaign): Observable<any> {
    return this.baseDataService.makePostCall(
      `${this.apiCampaignUrl}/quickAddCampaign`,
      quickAddCampaign
    );
  }

  getMeetingParticipants(
    meetingParticipantType: MeetingParticipantType
  ): Observable<MeetingParticipantTypeList[]> {
    return this.baseDataService.makePostCall(
      `${this.apiMeetingUrl}/getParticipants`,
      meetingParticipantType
    );
  }

  getAllNotesByReferenceId(
    referenceId: string,
    isRecentLast: boolean
  ): Observable<Note[]> {
    return this.baseDataService.makeGetCall(
      `${this.apiNoteUrl}/getAllNotesByReferenceId?referenceId=${referenceId}&isRecentLast=${isRecentLast}`
    );
  }

  getAuditHistoryByEntity(
    auditEvent: AuditEvent
  ): Observable<AuditEventPaginated> {
    return this.baseDataService.makePostCall(
      `${this.apiAuditEventUrl}/getAuditHistoryByEntity`,
      auditEvent
    );
  }
}
