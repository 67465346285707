import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordSharedService {
  constructor() {}
  private subjectOpenResetPasswordModal = new BehaviorSubject(null);

  setSubjectOpenResetPasswordModal(string: string) {
    this.subjectOpenResetPasswordModal.next(string);
  }

  getSubjectOpenResetPasswordModal(): Observable<string> {
    return this.subjectOpenResetPasswordModal.asObservable();
  }
}
