import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  AuditEvent,
  AuditEventPaginated,
} from '../../models/common-data.model';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { HelpersService } from '../../services/helpers.service';
@Component({
  selector: 'app-common-timeline',
  templateUrl: './common-timeline.component.html',
  styleUrls: ['./common-timeline.component.scss'],
})
export class CommonTimelineComponent {
  auditEvent: AuditEvent;
  pageIndex: number = 1;
  pageSize: number = 25;
  @Input() entityId: string;
  isLoading: boolean = false;
  maxDate: Date = new Date();
  auditEventList: AuditEventPaginated;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  minDate: Date = moment().subtract(6, 'months').toDate();

  range = new FormGroup({
    start: new FormControl<Date | null>(
      moment().startOf('day').subtract(7, 'days').toDate()
    ),
    end: new FormControl<Date | null>(moment().startOf('day').toDate()),
  });

  constructor(
    private commonService: CommonService,
    private helpersService: HelpersService
  ) {}

  ngOnInit() {
    this.getAuditHistoryByEntity();
  }

  getAuditHistoryByEntity() {
    this.auditEvent = {
      entityId: this.entityId,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      startDate: this.helpersService.setDateForDb(this.range.value.start),
      endDate: this.helpersService.setDateForDb(this.range.value.end),
    };

    if (this.range.value.start && this.range.value.end) {
      this.isLoading = true;

      this.commonService
        .getAuditHistoryByEntity(this.auditEvent)
        .pipe(takeUntil(this.destroy))
        .subscribe(
          (auditEventList: AuditEventPaginated) => {
            this.isLoading = false;
            if (this.pageIndex == 1) {
              this.auditEventList = auditEventList;
            } else {
              auditEventList.items.forEach((newElement) => {
                var index = this.auditEventList.items
                  .map((x) => x.key)
                  .indexOf(newElement.key);
                if (index != -1) {
                  this.auditEventList.items[index].items.push(
                    ...newElement.items
                  );
                } else {
                  this.auditEventList.items.push(newElement);
                }
              });
            }
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  handleSearch() {
    this.pageIndex = 1;
    this.getAuditHistoryByEntity();
  }

  handleReset() {
    this.pageIndex = 1;
    this.range = new FormGroup({
      start: new FormControl<Date | null>(
        moment().startOf('day').subtract(7, 'days').toDate()
      ),
      end: new FormControl<Date | null>(moment().startOf('day').toDate()),
    });
    this.getAuditHistoryByEntity();
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // User has scrolled to the bottom
      if (this.auditEventList?.totalItems > this.pageSize * this.pageIndex) {
        this.pageIndex++;
        this.getAuditHistoryByEntity();
      }
    }
  }
}
