import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { AccountSharedService } from 'src/app/home/accounts/services/account-shared.service';
import {
  CallViewDetails,
  CancelCall,
} from 'src/app/home/calls/models/calls.model';
import { CallsService } from 'src/app/home/calls/services/calls.service';
import { CampaignViewSharedService } from 'src/app/home/campaigns/campaign-view/services/campaign-view-shared.service';
import { ContactSharedService } from 'src/app/home/contacts/services/contact-shared.service';
import { DealViewSharedService } from 'src/app/home/deals/deal-view/services/deal-view-shared.service';
import { LeadsSharedService } from 'src/app/home/leads/services/leads-shared.service';
import { CommonSharedService } from 'src/app/shared/services/common-shared.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-call-cancel-modal',
  templateUrl: './call-cancel-modal.component.html',
  styleUrls: ['./call-cancel-modal.component.scss'],
})
export class CallCancelModalComponent {
  @ViewChild('template', { static: true }) template: any;

  callId: string;
  modalRef: BsModalRef;
  cancelCallForm: FormGroup;
  cancelCallReq: CancelCall;
  isLoading: boolean = false;
  callDetails: CallViewDetails;
  cancelCallDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  @Input() module: string = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private callsService: CallsService,
    private helpersService: HelpersService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private commonSharedService: CommonSharedService,
    private leadsSharedService: LeadsSharedService,
    private accountSharedService: AccountSharedService,
    private contactSharedService: ContactSharedService,
    private dealViewSharedService: DealViewSharedService,
    private campaignViewSharedService: CampaignViewSharedService
  ) {
    this.cancelCallDataSubject = commonSharedService
      .getSubjectOpenCallCancelModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((id: string) => {
        if (id) {
          this.callId = id;
          this.getCallDetailsById();
          this.showModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.cancelCallForm.reset();
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setSubjectOpenCallCancelModal(null);
  }

  ngOnInit(): void {
    this.createCancelCallForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-m',
    });
  }

  getCurrentDate() {
    return new Date();
  }

  createCancelCallForm() {
    this.cancelCallForm = this.fb.group({
      CallTo: [''],
      Note: [''],
    });
  }

  cancelForm() {
    this.modalRef?.hide();
    this.cancelCallForm.reset();
  }

  getCallDetailsById() {
    this.callsService
      .getCalls(this.callId)
      .pipe(takeUntil(this.destroy))
      .subscribe((call: CallViewDetails) => {
        this.callDetails = call;
        this.mapData();
      });
  }

  mapData() {
    this.cancelCallForm = this.fb.group({
      CallTo: [this.callDetails.callTo],
      Note: [''],
    });
  }

  cancelCall(formData: any) {
    this.setCallReqBody(formData);
    this.saveCallInDb();
  }

  setCallReqBody(formData: any) {
    this.cancelCallReq = {
      id: this.callId,
      note: formData.Note,
    };
  }

  saveCallInDb() {
    this.isLoading = true;
    this.callsService.cancelCall(this.cancelCallReq).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res) {
          this.cancelForm();
          this.helpersService.showSuccessToast('Cancelled Successfully');
          this.backClick();
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  backClick() {
    if (this.module) {
      if (this.module == 'Lead') {
        this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(
          true
        );
        this.leadsSharedService.setSubjectCallLogTableChange(true);
      } else if (this.module == 'Account') {
        this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(
          true
        );
        this.accountSharedService.setSubjectOpenCallTableChange(true);
      } else if (this.module == 'Contact') {
        this.commonSharedService.setSubjectContactOpenActivityCallListChanged(
          true
        );
        this.contactSharedService.setSubjectCallLogTableChange(true);
      } else if (this.module == 'Deal') {
        this.commonSharedService.setSubjectDealOpenActivityCallListChanged(
          true
        );
        this.dealViewSharedService.setSubjectCallLogTableChange(true);
      } else if (this.module == 'Campaign') {
        this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
          true
        );
        this.campaignViewSharedService.setSubjectCallLogTableChange(true);
      }
    } else {
      this.router.navigateByUrl('/calls');
    }
  }
}
