import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import {
  CallViewDetails,
  RescheduleCall,
} from 'src/app/home/calls/models/calls.model';
import { CallsService } from 'src/app/home/calls/services/calls.service';
import { CommonSharedService } from 'src/app/shared/services/common-shared.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-call-reshedule-modal',
  templateUrl: './call-reshedule-modal.component.html',
  styleUrls: ['./call-reshedule-modal.component.scss'],
})
export class CallResheduleModalComponent {
  @ViewChild('template', { static: true }) template: any;

  rescheduleDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  rescheduleForm: FormGroup;
  isLoading: boolean = false;
  callId: string;
  callDetails: CallViewDetails;
  rescheduleCallReq: RescheduleCall;
  @Input() module: string = null;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private commonSharedService: CommonSharedService,
    private callsService: CallsService,
    private router: Router
  ) {
    this.rescheduleDataSubject = commonSharedService
      .getSubjectOpenCallRescheduleModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((id: string) => {
        if (id) {
          this.callId = id;
          this.getCallDetailsById();
          this.showModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.rescheduleForm.reset();
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setSubjectOpenCallRescheduleModal(null);
  }

  ngOnInit(): void {
    this.createRescheduleForm();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-m',
    });
  }

  getCurrentDate() {
    return new Date();
  }

  createRescheduleForm() {
    this.rescheduleForm = this.fb.group({
      CallTo: [''],
      Date: ['', Validators.required],
      Time: ['', Validators.required],
      Note: [''],
    });
  }

  cancelForm() {
    this.modalRef?.hide();
    this.rescheduleForm.reset();
  }

  getCallDetailsById() {
    this.callsService
      .getCalls(this.callId)
      .pipe(takeUntil(this.destroy))
      .subscribe((call: CallViewDetails) => {
        this.callDetails = call;
        this.mapData();
      });
  }

  mapData() {
    this.rescheduleForm = this.fb.group({
      CallTo: [this.callDetails.callTo],
      Date: [
        this.helpersService.getDateFromDbFormatted(this.callDetails.startTime),
        Validators.required,
      ],
      Time: [
        this.helpersService.getTimeFromDb(this.callDetails.startTime),
        Validators.required,
      ],
      Note: [''],
    });
  }

  rescheduleCall(fromData: any) {
    this.setCallReqBody(fromData);
    this.saveCallInDb();
  }

  setCallReqBody(formData: any) {
    var Date = moment(formData.Date).format('DD-MM-YYYY');
    var rescheduleDate = moment(
      Date + ' ' + formData.Time,
      'DD-MM-YYYY hh:mm A'
    ).toISOString();

    this.rescheduleCallReq = {
      id: this.callId,
      rescheduleDate: rescheduleDate,
      notes: formData.Note,
    };
  }

  saveCallInDb() {
    this.isLoading = true;
    this.callsService.rescheduleCall(this.rescheduleCallReq).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res) {
          this.cancelForm();
          this.helpersService.showSuccessToast('Rescheduled Successfully');
          this.backClick();
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  backClick() {
    if (this.module) {
      if (this.module == 'Lead') {
        this.commonSharedService.setSubjectLeadOpenActivityCallListChanged(
          true
        );
      } else if (this.module == 'Account') {
        this.commonSharedService.setSubjectAccountOpenActivityCallListChanged(
          true
        );
      } else if (this.module == 'Contact') {
        this.commonSharedService.setSubjectContactOpenActivityCallListChanged(
          true
        );
      } else if (this.module == 'Deal') {
        this.commonSharedService.setSubjectDealOpenActivityCallListChanged(
          true
        );
      } else if (this.module == 'Campaign') {
        this.commonSharedService.setSubjectCampaignOpenActivityCallListChanged(
          true
        );
      }
    } else {
      this.router.navigateByUrl('/calls');
    }
  }
}
