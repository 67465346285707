import { Component, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import {
  CampaignList,
  CampaignGroupedDataSource,
  CampaignPaginated,
  CampaignGroupedList,
} from 'src/app/home/campaigns/campaign-list/models/campaign-list.model';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { Constants } from 'src/app/shared/data/constants';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/service/language.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { CampaignListService } from 'src/app/home/campaigns/campaign-list/services/campaign-list.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { CommonChooseListSharedService } from 'src/app/shared/services/common-choose-list-shared.service';

@Component({
  selector: 'app-campaign-list-modal',
  templateUrl: './campaign-list-modal.component.html',
  styleUrls: ['./campaign-list-modal.component.scss'],
})
export class CampaignListModalComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  campaignListSubject: Subscription;
  campaignIdSubject: Subscription;
  campaignListChangedSubject: Subscription;
  campaignList: CampaignList[] = [];
  displayedColumns: string[] = [
    'Select',
    'CampaignName',
    'Type',
    'Status',
    'StartDate',
    'EndDate',
    'CampaignOwner',
  ];
  dataSources: CampaignGroupedDataSource[];
  dataSource = new MatTableDataSource<CampaignList>(this.campaignList);
  modalRef: BsModalRef;
  baseFilterVm: BaseFilterVm;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('template', { static: true }) template: any;
  isLoading: boolean = false;
  selectedCampaignId: string = null;
  // pageSize = Constants.MAX_PAGE_SIZE;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageLength: number = 0;
  totalItems: number = 0;

  campaignPaginated: CampaignPaginated;
  campaignGroupedList: CampaignGroupedList[] = [];

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private paginatorIntl: MatPaginatorIntl,
    public helpersService: HelpersService,
    private modalService: BsModalService,
    private commonChooseListSharedService: CommonChooseListSharedService,
    private campaignListService: CampaignListService,
    private quickCreateSharedService: QuickCreateSharedService
  ) {
    this.campaignListSubject = commonChooseListSharedService
      .getSubjectOpenCampaignListModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
          this.getAllCampaigns();
        }
      });

    this.campaignIdSubject = this.commonChooseListSharedService
      .getSubjectSelectedCampaignId()
      .pipe(takeUntil(this.destroy))
      .subscribe((campaingnId: string) => {
        if (campaingnId != null) {
          this.selectedCampaignId = campaingnId;
        }
      });

    this.campaignListChangedSubject = this.quickCreateSharedService
      .getSubjectChooseCampaignListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllCampaigns();
        }
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.languageService
      .getLanguageChangedSubject()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: string) => {
        if (flag != null) {
          this.setTranslations();
        }
      });
  }
  ngOnInit(): void {}

  setTranslations() {
    this.translateService
      .get(['MASTER_MENU.ITEMS_PER_PAGE'])
      .subscribe((translations) => {
        this.paginatorIntl.itemsPerPageLabel =
          translations['MASTER_MENU.ITEMS_PER_PAGE'];
      });
  }

  createCampaign() {
    this.quickCreateSharedService.setSubjectQuickCreateCampaignOpenModal(
      'Open'
    );
  }

  setFilterData() {
    this.baseFilterVm = {
      searchText: '',
      groupBy: '',
      sortBy: '',
      filters: [
        {
          condition: 'Equal',
          filterBy: 'ApprovalStatus',
          filterClause: 'AND',
          value: 'Approved',
          dataType: 'STRING',
          lookupValueList: [],
        },
      ],
      isDescending: false,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };
  }

  getAllCampaigns() {
    this.setFilterData();
    this.campaignGroupedList = [];
    this.isLoading = true;
    this.dataSources = [];
    this.dataSource.data = [];
    this.campaignListService
      .getAllCampaigns(this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: CampaignPaginated | CampaignGroupedList[]) => {
          if ('totalItems' in res) {
            this.campaignPaginated = res;
            this.campaignList = res.items;
            this.dataSource = new MatTableDataSource<CampaignList>(res.items);
            this.totalItems = res.totalItems;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getAllCampaigns();
  }

  ngOnDestroy(): void {
    this.commonChooseListSharedService.setSubjectOpenCampaignListModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  onRadioButtonChange(event: Event): void {
    // event contains the selected value
    console.log('Selected option:', this.selectedCampaignId);
  }

  chooseCampaign() {
    this.commonChooseListSharedService.setSubjectSelectedCampaignId(
      this.selectedCampaignId
    );
    this.modalRef.hide();
    this.commonChooseListSharedService.setSubjectOpenCampaignListModal(null);
  }

  handleCancel() {
    this.modalRef.hide();
    this.selectedCampaignId = null;
    // this.leadsSharedService.setSubjectConvertLeadCampaignSourceId(null);
    this.commonChooseListSharedService.setSubjectOpenCampaignListModal(null);
  }
}
