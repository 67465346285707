import {
  ACCOUNTS_ROLES,
  ADMINISTRATION_ROLES,
  APPROVAL_WORKFLOW_LIST_ROLES,
  CALLS_ROLES,
  CAMPAIGNS_ROLES,
  CONFIGURATIONS_ROLES,
  CONTACTS_ROLES,
  CRM_ADMIN_ROLE,
  DASHBOARD_ROLES,
  DEALS_ROLES,
  INQUIRY_ROLES,
  LEADS_ROLES,
  MASTER_ROLES,
  MEETINGS_ROLES,
  REPORTS_ROLES,
  TASKS_ROLES,
} from 'src/app/shared/data/constants';
import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'MAIN_MENU.DASHBOARD',
    iconType: 'material-icons-two-tone',
    icon: 'dashboard',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [],
    submenu: [],
  },
  {
    path: 'approvalWorkflowList',
    title: 'MAIN_MENU.APPROVAL_WORKFLOW_ACTION_HISTORY',
    iconType: 'material-icons-two-tone',
    icon: 'verified',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      APPROVAL_WORKFLOW_LIST_ROLES.create,
      APPROVAL_WORKFLOW_LIST_ROLES.edit,
      APPROVAL_WORKFLOW_LIST_ROLES.export,
      APPROVAL_WORKFLOW_LIST_ROLES.delete,
      APPROVAL_WORKFLOW_LIST_ROLES.print,
      APPROVAL_WORKFLOW_LIST_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: '',
    title: 'MAIN_MENU.INQUIRY',
    iconType: 'material-icons-two-tone',
    icon: 'note_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      INQUIRY_ROLES.create,
      INQUIRY_ROLES.edit,
      INQUIRY_ROLES.export,
      INQUIRY_ROLES.delete,
      INQUIRY_ROLES.print,
      INQUIRY_ROLES.readonly,
    ],
    submenu: [
      {
        path: 'inquiry',
        title: 'INQUIRY_MENU.INQUIRY_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'note_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          INQUIRY_ROLES.create,
          INQUIRY_ROLES.edit,
          INQUIRY_ROLES.export,
          INQUIRY_ROLES.delete,
          INQUIRY_ROLES.print,
          INQUIRY_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'inquiry/pendingLeads',
        title: 'INQUIRY_MENU.CONVERT_TO_LEADS_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'note_alt',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          INQUIRY_ROLES.create,
          INQUIRY_ROLES.edit,
          INQUIRY_ROLES.export,
          INQUIRY_ROLES.delete,
          INQUIRY_ROLES.print,
          INQUIRY_ROLES.readonly,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MAIN_MENU.LEADS',
    iconType: 'material-icons-two-tone',
    icon: 'star',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      LEADS_ROLES.create,
      LEADS_ROLES.edit,
      LEADS_ROLES.export,
      LEADS_ROLES.delete,
      LEADS_ROLES.print,
      LEADS_ROLES.readonly,
    ],
    submenu: [
      {
        path: 'leads',
        title: 'LEADS_MENU.LEADS_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'star',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          LEADS_ROLES.create,
          LEADS_ROLES.edit,
          LEADS_ROLES.export,
          LEADS_ROLES.delete,
          LEADS_ROLES.print,
          LEADS_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'leads/pendingDeals',
        title: 'LEADS_MENU.CONVERT_LEADS_LIST',
        iconType: 'material-icons-two-tone',
        icon: 'star',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          LEADS_ROLES.create,
          LEADS_ROLES.edit,
          LEADS_ROLES.export,
          LEADS_ROLES.delete,
          LEADS_ROLES.print,
          LEADS_ROLES.readonly,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: 'contacts/contactList',
    title: 'MAIN_MENU.CONTACTS',
    iconType: 'material-icons-two-tone',
    icon: 'contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      CONTACTS_ROLES.create,
      CONTACTS_ROLES.edit,
      CONTACTS_ROLES.export,
      CONTACTS_ROLES.delete,
      CONTACTS_ROLES.print,
      CONTACTS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: 'accounts/accountList',
    title: 'MAIN_MENU.ACCOUNTS',
    iconType: 'material-icons-two-tone',
    icon: 'account_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      ACCOUNTS_ROLES.create,
      ACCOUNTS_ROLES.edit,
      ACCOUNTS_ROLES.export,
      ACCOUNTS_ROLES.delete,
      ACCOUNTS_ROLES.print,
      ACCOUNTS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: 'deals/dealList',
    title: 'MAIN_MENU.DEALS',
    iconType: 'material-icons-two-tone',
    icon: 'report',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      DEALS_ROLES.create,
      DEALS_ROLES.edit,
      DEALS_ROLES.export,
      DEALS_ROLES.delete,
      DEALS_ROLES.print,
      DEALS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: 'tasks',
    title: 'MAIN_MENU.TASKS',
    iconType: 'material-icons-two-tone',
    icon: 'task',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      TASKS_ROLES.create,
      TASKS_ROLES.edit,
      TASKS_ROLES.export,
      TASKS_ROLES.delete,
      TASKS_ROLES.print,
      TASKS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: '/meetings',
    title: 'MAIN_MENU.MEETINGS',
    iconType: 'material-icons-two-tone',
    icon: 'groups',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      MEETINGS_ROLES.create,
      MEETINGS_ROLES.edit,
      MEETINGS_ROLES.export,
      MEETINGS_ROLES.delete,
      MEETINGS_ROLES.print,
      MEETINGS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: 'calls',
    title: 'MAIN_MENU.CALLS',
    iconType: 'material-icons-two-tone',
    icon: 'phone_in_talk',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      CALLS_ROLES.create,
      CALLS_ROLES.edit,
      CALLS_ROLES.export,
      CALLS_ROLES.delete,
      CALLS_ROLES.print,
      CALLS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: 'campaigns/campaign-list',
    title: 'MAIN_MENU.CAMPAIGNS',
    iconType: 'material-icons-two-tone',
    icon: 'campaign',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      CAMPAIGNS_ROLES.create,
      CAMPAIGNS_ROLES.edit,
      CAMPAIGNS_ROLES.export,
      CAMPAIGNS_ROLES.delete,
      CAMPAIGNS_ROLES.print,
      CAMPAIGNS_ROLES.readonly,
    ],
    submenu: [],
  },
  {
    path: '',
    title: 'COMMON.MASTER',
    iconType: 'material-icons-two-tone',
    icon: 'table_view',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      MASTER_ROLES.create,
      MASTER_ROLES.edit,
      MASTER_ROLES.export,
      MASTER_ROLES.delete,
      MASTER_ROLES.print,
      MASTER_ROLES.readonly,
    ],
    submenu: [
      {
        path: 'master/accountMaster',
        title: 'MASTER_MENU.ACCOUNT_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/scheduleCallMaster',
        title: 'COMMON.SCHEDULE_CALL_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/dealMaster',
        title: 'COMMON.DEAL_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/inquiry',
        title: 'COMMON.INQUIRY_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/leadMaster',
        title: 'COMMON.LEAD_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },

      {
        path: 'master/meetingMaster',
        title: 'COMMON.MEETING_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/sisWebMaster',
        title: 'MASTER_MENU.SIS_WEB_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: 'master/taskMaster',
        title: 'COMMON.TASK_MASTER',
        iconType: 'material-icons-two-tone',
        icon: 'table_view',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          MASTER_ROLES.create,
          MASTER_ROLES.edit,
          MASTER_ROLES.export,
          MASTER_ROLES.delete,
          MASTER_ROLES.print,
          MASTER_ROLES.readonly,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MAIN_MENU.ADMINISTRATOR',
    iconType: 'material-icons-two-tone',
    icon: 'admin_panel_settings',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      ADMINISTRATION_ROLES.create,
      ADMINISTRATION_ROLES.edit,
      ADMINISTRATION_ROLES.export,
      ADMINISTRATION_ROLES.delete,
      ADMINISTRATION_ROLES.print,
      ADMINISTRATION_ROLES.readonly,
    ],
    submenu: [
      {
        path: '/administration/systemApplications',
        title: 'MAIN_MENU.SYSTEM_APPLICATIONS',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '/administration/systemModules',
        title: 'MAIN_MENU.SYSTEM_MODULES',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '',
        title: 'MAIN_MENU.MANAGE_ROLES',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/administration/manageRoles/rolesClassification',
            title: 'ADMINISTRATION.ROLE_CLASSIFICATION',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              ADMINISTRATION_ROLES.create,
              ADMINISTRATION_ROLES.edit,
              ADMINISTRATION_ROLES.export,
              ADMINISTRATION_ROLES.delete,
              ADMINISTRATION_ROLES.print,
              ADMINISTRATION_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/administration/manageRoles/rolesManagement',
            title: 'ADMINISTRATION.ROLE_MANAGEMENT',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              ADMINISTRATION_ROLES.create,
              ADMINISTRATION_ROLES.edit,
              ADMINISTRATION_ROLES.export,
              ADMINISTRATION_ROLES.delete,
              ADMINISTRATION_ROLES.print,
              ADMINISTRATION_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '/administration/manageUsers',
        title: 'MAIN_MENU.MANAGE_USERS',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '/administration/workflowUserGroup',
        title: 'MAIN_MENU.WORKFLOW_USER_GROUP',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '/administration/approvalWorkflows',
        title: 'MAIN_MENU.APPROVAL_WORKFLOWS',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '/administration/companyProfile',
        title: 'MAIN_MENU.COMPANY_PROFILE',
        iconType: 'material-icons-two-tone',
        class: '',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          ADMINISTRATION_ROLES.create,
          ADMINISTRATION_ROLES.edit,
          ADMINISTRATION_ROLES.export,
          ADMINISTRATION_ROLES.delete,
          ADMINISTRATION_ROLES.print,
          ADMINISTRATION_ROLES.readonly,
        ],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MAIN_MENU.REPORTS',
    iconType: 'material-icons-two-tone',
    icon: 'bar_chart',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      REPORTS_ROLES.create,
      REPORTS_ROLES.edit,
      REPORTS_ROLES.export,
      REPORTS_ROLES.delete,
      REPORTS_ROLES.print,
      REPORTS_ROLES.readonly,
    ],
    submenu: [
      {
        path: '',
        title: 'MAIN_MENU.INQUIRY_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/inquiryReports/inquiryStatus',
            title: 'REPORT_MENU.INQUIRY_BY_STATUS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/inquiryReports/todayInquiry',
            title: 'REPORT_MENU.TODAY_INQUIRY',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/inquiryReports/inquirySource',
            title: 'REPORT_MENU.INQUIRY_BY_SOURCE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/inquiryReports/inquiryIndustry',
            title: 'REPORT_MENU.INQUIRY_BY_INDUSTRY',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/inquiryReports/inquiryConverted',
            title: 'REPORT_MENU.CONVERTED_INQUIRY',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'MAIN_MENU.LEAD_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/leadReports/leadStatus',
            title: 'REPORT_MENU.LEAD_BY_STATUS',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/leadReports/todayLeads',
            title: 'REPORT_MENU.TODAY_LEADS',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/leadReports/leadSource',
            title: 'REPORT_MENU.LEAD_BY_SOURCE',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/leadReports/leadOwnership',
            title: 'REPORT_MENU.LEAD_BY_OWNERSHIP',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/leadReports/leadIndustry',
            title: 'REPORT_MENU.LEAD_BY_INDUSTRY',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/leadReports/convertedLeads',
            title: 'REPORT_MENU.CONVERTED_LEADS',
            iconType: 'material-icons-two-tone',
            class: 'menu-toggle',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'MAIN_MENU.ACCOUNT_AND_CONTACT_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/accountAndContactReports/keyAccountsListReports',
            title: 'REPORT_MENU.KEY_ACCOUNTS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/accountAndContactReports/accountsByIndustryReports',
            title: 'REPORT_MENU.ACCOUNTS_BY_INDUSTRY',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/accountAndContactReports/contactMailingListReports',
            title: 'REPORT_MENU.CONTACT_MAILING_LIST',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'MAIN_MENU.DEAL_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/dealReports/openDeals',
            title: 'REPORT_MENU.OPEN_DEALS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/todaysSales',
            title: 'REPORT_MENU.TODAYS_SALES',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/stageVsDealType',
            title: 'REPORT_MENU.STAGE_VS_DEALTYPE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/salespersonsPerformance',
            title: 'REPORT_MENU.SALESPERSONS_PERFORMANCE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/salesByLeadSource',
            title: 'REPORT_MENU.SALES_BY_LEAD_SOURCE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/salesThisMonth',
            title: 'REPORT_MENU.SALES_THIS_MONTH',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/pipelineByStage',
            title: 'REPORT_MENU.PIPELINE_BY_STAGE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/pipelineByProbability',
            title: 'REPORT_MENU.PIPELINE_BY_PROBABILITY',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/lostDeals',
            title: 'REPORT_MENU.LOST_DEALS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/dealsByType',
            title: 'REPORT_MENU.DEALS_BY_TYPE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/dealReports/dealsClosingThisMonth',
            title: 'REPORT_MENU.DEALS_CLOSING_THIS_MONTH',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'MAIN_MENU.CAMPAIGN_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/campaignReports/campaignLeadsReports',
            title: 'REPORT_MENU.CAMPAIGN_LEADS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/campaignReports/campaignRevenueReports',
            title: 'REPORT_MENU.CAMPAIGN_REVENUE',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: '',
        title: 'MAIN_MENU.EMAIL_REPORTS',
        iconType: 'material-icons-two-tone',
        class: 'menu-toggle',
        groupTitle: false,
        icon: '',
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          REPORTS_ROLES.create,
          REPORTS_ROLES.edit,
          REPORTS_ROLES.export,
          REPORTS_ROLES.delete,
          REPORTS_ROLES.print,
          REPORTS_ROLES.readonly,
        ],
        submenu: [
          {
            path: '/reports/emailReports/topTenUsersReport',
            title: 'REPORT_MENU.TOP_TEN_USERS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: '/reports/emailReports/sentEmailStatus',
            title: 'REPORT_MENU.SENT_EMAIL_STATUS',
            iconType: 'material-icons-two-tone',
            class: '',
            groupTitle: false,
            icon: '',
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              REPORTS_ROLES.create,
              REPORTS_ROLES.edit,
              REPORTS_ROLES.export,
              REPORTS_ROLES.delete,
              REPORTS_ROLES.print,
              REPORTS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      // {
      //   path: '/maintanance/next-phase',
      //   title: 'MAIN_MENU.CUSTOM_REPORTS',
      //   iconType: 'material-icons-two-tone',
      //   class: '',
      //   groupTitle: false,
      //   icon: '',
      //   badge: '',
      //   badgeClass: '',
      //   role: [
      //     CRM_ADMIN_ROLE,
      //     REPORTS_ROLES.create,
      //     REPORTS_ROLES.edit,
      //     REPORTS_ROLES.export,
      //     REPORTS_ROLES.delete,
      //     REPORTS_ROLES.print,
      //     REPORTS_ROLES.readonly,
      //   ],
      //   submenu: [],
      // },
    ],
  },
  {
    path: '',
    title: 'COMMON.CONFIGURATIONS',
    iconType: 'material-icons-two-tone',
    icon: 'settings',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: [
      CRM_ADMIN_ROLE,
      CONFIGURATIONS_ROLES.create,
      CONFIGURATIONS_ROLES.edit,
      CONFIGURATIONS_ROLES.export,
      CONFIGURATIONS_ROLES.delete,
      CONFIGURATIONS_ROLES.print,
      CONFIGURATIONS_ROLES.readonly,
    ],
    submenu: [
      {
        path: 'configurations/externalConfigurations/list',
        title: 'COMMON.EXTERNAL_CONFIGURATIONS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          CONFIGURATIONS_ROLES.create,
          CONFIGURATIONS_ROLES.edit,
          CONFIGURATIONS_ROLES.export,
          CONFIGURATIONS_ROLES.delete,
          CONFIGURATIONS_ROLES.print,
          CONFIGURATIONS_ROLES.readonly,
        ],
        submenu: [],
      },
      {
        path: '',
        title: 'CONFIGURATION_MENU.NOTIFICATION_CONFIGURATION',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: 'menu-toggle',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          CONFIGURATIONS_ROLES.create,
          CONFIGURATIONS_ROLES.edit,
          CONFIGURATIONS_ROLES.export,
          CONFIGURATIONS_ROLES.delete,
          CONFIGURATIONS_ROLES.print,
          CONFIGURATIONS_ROLES.readonly,
        ],
        submenu: [
          {
            path: 'configurations/notificationTemplate/list',
            title: 'CONFIGURATION_MENU.MANAGE_CONFIGURATION',
            iconType: 'material-icons-two-tone',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              CONFIGURATIONS_ROLES.create,
              CONFIGURATIONS_ROLES.edit,
              CONFIGURATIONS_ROLES.export,
              CONFIGURATIONS_ROLES.delete,
              CONFIGURATIONS_ROLES.print,
              CONFIGURATIONS_ROLES.readonly,
            ],
            submenu: [],
          },
          {
            path: 'configurations/liquidVariableConfiguration',
            title: 'CONFIGURATION_MENU.MANAGE_LIQUID_VARIABLE',
            iconType: 'material-icons-two-tone',
            icon: '',
            class: '',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: [
              CRM_ADMIN_ROLE,
              CONFIGURATIONS_ROLES.create,
              CONFIGURATIONS_ROLES.edit,
              CONFIGURATIONS_ROLES.export,
              CONFIGURATIONS_ROLES.delete,
              CONFIGURATIONS_ROLES.print,
              CONFIGURATIONS_ROLES.readonly,
            ],
            submenu: [],
          },
        ],
      },
      {
        path: 'configurations/systemConfigurations/list',
        title: 'CONFIGURATION_MENU.SYSTEM_CONFIGURATIONS',
        iconType: 'material-icons-two-tone',
        icon: '',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [
          CRM_ADMIN_ROLE,
          CONFIGURATIONS_ROLES.create,
          CONFIGURATIONS_ROLES.edit,
          CONFIGURATIONS_ROLES.export,
          CONFIGURATIONS_ROLES.delete,
          CONFIGURATIONS_ROLES.print,
          CONFIGURATIONS_ROLES.readonly,
        ],
        submenu: [],
      },
    ],
  },
];
