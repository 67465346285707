import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignViewSharedService {

  private subjectOpenCallsAddModal = new BehaviorSubject(null);
  private subjectCallLogTableChange = new BehaviorSubject(null);
  private subjectNumberOfOpenCalls = new BehaviorSubject(null);

  constructor() { }

  setsubjectOpenMeetingCallsModal(string: string) {
    this.subjectOpenCallsAddModal.next(string);
  }
  getsubjectOpenMeetingCallsModal(): Observable<string> {
    return this.subjectOpenCallsAddModal.asObservable();
  }

   setSubjectCallLogTableChange(flag: boolean) {
    return this.subjectCallLogTableChange.next(flag);
  }

  getSubjectCallLogTableChange(): Observable<boolean> {
    return this.subjectCallLogTableChange.asObservable();
  }

  setSubjectNumberOfOpenCalls(number: number) {
    return this.subjectNumberOfOpenCalls.next(number);
  }

  getSubjectNumberOfOpenCalls(): Observable<any> {
    return this.subjectNumberOfOpenCalls.asObservable();
  }
}
