import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import {
  DealGroupedDataSource,
  DealGroupedList,
  DealList,
  DealPaginated,
} from 'src/app/home/deals/deal-list/models/deal-list.model';
import { Constants } from 'src/app/shared/data/constants';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { DealListService } from 'src/app/home/deals/deal-list/services/deal-list.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';
import { CommonChooseListSharedService } from 'src/app/shared/services/common-choose-list-shared.service';

@Component({
  selector: 'app-deal-list-modal',
  templateUrl: './deal-list-modal.component.html',
  styleUrls: ['./deal-list-modal.component.sass'],
})
export class DealListModalComponent {
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  dealListSubject: Subscription;
  dealIdSubject: Subscription;
  dealListChangedSubject: Subscription;
  dealList: DealList[] = [];
  displayedColumns: string[] = [
    'Select',
    'DealName',
    'Amount',
    'Stage',
    'ClosingDate',
    'AccountName',
    'ContactName',
    'DealOwner',
    'ApprovalStatus',
  ];
  dataSource = new MatTableDataSource<DealList>(this.dealList);
  modalRef: BsModalRef;
  baseFilterVm: BaseFilterVm;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('template', { static: true }) template: any;
  isLoading: boolean = false;
  selectedDealId: string = null;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageLength: number = 0;
  totalItems: number = 0;
  dealPaginated: DealPaginated;
  dealGroupedList: DealGroupedList[] = [];
  dataSources: DealGroupedDataSource[];

  constructor(
    public helpersService: HelpersService,
    private modalService: BsModalService,
    private commonChooseListSharedService: CommonChooseListSharedService,
    private dealService: DealListService,
    private quickCreateSharedService: QuickCreateSharedService
  ) {
    this.dealListSubject = commonChooseListSharedService
      .getSubjectDealListModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
          this.getAllDeals();
        }
      });

    this.dealIdSubject = this.commonChooseListSharedService
      .getSubjectSelectedDealId()
      .pipe(takeUntil(this.destroy))
      .subscribe((dealId: string) => {
        this.selectedDealId = dealId;
      });

    this.dealListChangedSubject = this.commonChooseListSharedService
      .getSubjectDealListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllDeals();
        }
      });

    this.dealListChangedSubject = this.quickCreateSharedService
      .getSubjectChooseDealListTableChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((flag: boolean) => {
        if (flag) {
          this.getAllDeals();
        }
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-xl',
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  setFilterData() {
    this.baseFilterVm = {
      searchText: '',
      groupBy: '',
      sortBy: '',
      filters: [
        {
          condition: 'Equal',
          filterBy: 'ApprovalStatus',
          filterClause: 'AND',
          value: 'Approved',
          dataType: 'STRING',
          lookupValueList: [],
        },
      ],
      isDescending: false,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      sortByList: [],
    };
  }

  getAllDeals() {
    this.setFilterData();
    this.dealGroupedList = [];
    this.isLoading = true;
    this.dataSources = [];
    this.dataSource.data = [];
    this.dealService
      .getAllDeals(this.baseFilterVm)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res: DealPaginated | DealGroupedList[]) => {
          if ('totalItems' in res) {
            this.dealPaginated = res;
            this.dealList = res.items;
            if (Array.isArray(res.items)) {
              this.dataSource = new MatTableDataSource<DealList>(res.items);
            }
            this.totalItems = res.totalItems;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllDeals();
  }

  onRadioButtonChange(event: Event): void {
    console.log('Selected option:', this.selectedDealId);
  }

  onClickChooseDeal() {
    this.commonChooseListSharedService.setSubjectSelectedDealId(
      this.selectedDealId
    );
    this.modalRef.hide();
    this.commonChooseListSharedService.setSubjectDealListModal(null);
  }

  createDeal() {
    this.quickCreateSharedService.setSubjectQuickCreateDealOpenModal('Open');
  }

  handleCancel() {
    this.modalRef.hide();
    this.selectedDealId = null;
    this.commonChooseListSharedService.setSubjectDealListModal(null);
  }

  ngOnDestroy(): void {
    this.commonChooseListSharedService.setSubjectDealListModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }
}
