import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeetingParticipant, Repeat } from '../models/common-data.model';

@Injectable({
  providedIn: 'root',
})
export class CommonSharedService {
  constructor() {}

  private subjectQuickAddCampignModal = new BehaviorSubject(null);
  private subjectLeadAssignCampaignsTableChanged = new BehaviorSubject(null);
  private subjectContactAssignCampaignsTableChanged = new BehaviorSubject(null);
  private subjectAccountAssignCampaignsTableChanged = new BehaviorSubject(null);
  private subjectCompanyListChanged = new BehaviorSubject(null);
  private subjectOpenMeetingParticipantsModal = new BehaviorSubject(null);
  private subjectMeetingParticipants = new BehaviorSubject(null);
  private subjectOpenCallModal = new BehaviorSubject(null);
  private subjectOpenCallRescheduleModal = new BehaviorSubject(null);
  private subjectOpenCallCancelModal = new BehaviorSubject(null);

  private subjectLeadOpenActivityCallListChanged = new BehaviorSubject(null);
  private subjectAccountOpenActivityCallListChanged = new BehaviorSubject(null);
  private subjectContactOpenActivityCallListChanged = new BehaviorSubject(null);
  private subjectDealOpenActivityCallListChanged = new BehaviorSubject(null);
  private subjectCampaignOpenActivityCallListChanged = new BehaviorSubject(
    null
  );
  private subjectOpenRepeatModal = new BehaviorSubject(null);
  private subjectRepeatData = new BehaviorSubject(null);
  private subjectCancelRepeatModal = new BehaviorSubject(null);
  private subjectIsApplyAllUpcoming = new BehaviorSubject(null);
  private subjectSelectedOpenActivity = new BehaviorSubject(null);

  setSubjectQuickAddCampignModal(str: string) {
    this.subjectQuickAddCampignModal.next(str);
  }

  getSubjectQuickAddCampignModal(): Observable<any> {
    return this.subjectQuickAddCampignModal.asObservable();
  }

  setSubjectAssignCampaignsTableChanged(flag: boolean) {
    this.subjectLeadAssignCampaignsTableChanged.next(flag);
  }

  getSubjectAssignCampaignsTableChanged(): Observable<any> {
    return this.subjectLeadAssignCampaignsTableChanged.asObservable();
  }

  setSubjectContactAssignCampaignsTableChanged(flag: boolean) {
    this.subjectContactAssignCampaignsTableChanged.next(flag);
  }

  getSubjectContactAssignCampaignsTableChanged(): Observable<any> {
    return this.subjectContactAssignCampaignsTableChanged.asObservable();
  }

  setSubjectAccountAssignCampaignsTableChanged(flag: boolean) {
    this.subjectAccountAssignCampaignsTableChanged.next(flag);
  }

  getSubjectAccountAssignCampaignsTableChanged(): Observable<any> {
    return this.subjectAccountAssignCampaignsTableChanged.asObservable();
  }

  setSubjectCompanyListChanged(flag: boolean) {
    this.subjectCompanyListChanged.next(flag);
  }

  getSubjectCompanyListChanged(): Observable<any> {
    return this.subjectCompanyListChanged.asObservable();
  }

  setSubjectOpenMeetingParticipantsModal(str: string) {
    this.subjectOpenMeetingParticipantsModal.next(str);
  }

  getSubjectOpenMeetingParticipantsModal(): Observable<any> {
    return this.subjectOpenMeetingParticipantsModal.asObservable();
  }

  setSubjectMeetingParticipants(meetingParticipants: MeetingParticipant[]) {
    this.subjectMeetingParticipants.next(meetingParticipants);
  }

  getSubjectMeetingParticipants(): Observable<any> {
    return this.subjectMeetingParticipants.asObservable();
  }

  // common call modal
  setsubjectOpenMeetingCallModal(obj: any) {
    this.subjectOpenCallModal.next(obj);
  }
  getsubjectOpenMeetingCallModal(): Observable<string> {
    return this.subjectOpenCallModal.asObservable();
  }

  // open activity call list changed
  setSubjectLeadOpenActivityCallListChanged(flag: boolean) {
    this.subjectLeadOpenActivityCallListChanged.next(flag);
  }

  getSubjectLeadOpenActivityCallListChanged(): Observable<any> {
    return this.subjectLeadOpenActivityCallListChanged.asObservable();
  }

  setSubjectAccountOpenActivityCallListChanged(flag: boolean) {
    this.subjectAccountOpenActivityCallListChanged.next(flag);
  }

  getSubjectAccountOpenActivityCallListChanged(): Observable<any> {
    return this.subjectAccountOpenActivityCallListChanged.asObservable();
  }

  setSubjectContactOpenActivityCallListChanged(flag: boolean) {
    this.subjectContactOpenActivityCallListChanged.next(flag);
  }

  getSubjectContactOpenActivityCallListChanged(): Observable<any> {
    return this.subjectContactOpenActivityCallListChanged.asObservable();
  }

  setSubjectDealOpenActivityCallListChanged(flag: boolean) {
    this.subjectDealOpenActivityCallListChanged.next(flag);
  }

  getSubjectDealOpenActivityCallListChanged(): Observable<any> {
    return this.subjectDealOpenActivityCallListChanged.asObservable();
  }

  setSubjectCampaignOpenActivityCallListChanged(flag: boolean) {
    this.subjectCampaignOpenActivityCallListChanged.next(flag);
  }

  getSubjectCampaignOpenActivityCallListChanged(): Observable<any> {
    return this.subjectCampaignOpenActivityCallListChanged.asObservable();
  }
  setSubjectOpenCallRescheduleModal(str: string) {
    this.subjectOpenCallRescheduleModal.next(str);
  }

  getSubjectOpenCallRescheduleModal(): Observable<any> {
    return this.subjectOpenCallRescheduleModal.asObservable();
  }

  setSubjectOpenCallCancelModal(str: string) {
    this.subjectOpenCallCancelModal.next(str);
  }

  getSubjectOpenCallCancelModal(): Observable<any> {
    return this.subjectOpenCallCancelModal.asObservable();
  }

  setSubjectOpenRepeatModal(str: string) {
    this.subjectOpenRepeatModal.next(str);
  }

  getSubjectOpenRepeatModal(): Observable<any> {
    return this.subjectOpenRepeatModal.asObservable();
  }

  setSubjectRepeatData(repeat: Repeat) {
    this.subjectRepeatData.next(repeat);
  }

  getSubjectRepeatData(): Observable<any> {
    return this.subjectRepeatData.asObservable();
  }

  setSubjectCancelRepeatModal(flag: boolean) {
    this.subjectCancelRepeatModal.next(flag);
  }

  getSubjectCancelRepeatModal(): Observable<any> {
    return this.subjectCancelRepeatModal.asObservable();
  }

  setSubjectIsApplyAllUpcoming(flag: boolean) {
    this.subjectIsApplyAllUpcoming.next(flag);
  }

  getSubjectIsApplyAllUpcoming(): Observable<any> {
    return this.subjectIsApplyAllUpcoming.asObservable();
  }

  setSubjectSelectedOpenActivity(index: number) {
    this.subjectSelectedOpenActivity.next(index);
  }

  getSubjectSelectedOpenActivity(): Observable<number> {
    return this.subjectSelectedOpenActivity.asObservable();
  }
}
