<ng-template #template>
  <h5 class="modal-title modal-container quick-create-modal-rtl">
    Quick Create Account
  </h5>
  <div class="container modal-container">
    <form [formGroup]="accountForm">
      <div class="row quick-create-modal-rtl">
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'ACCOUNTS_MENU.ACCOUNT_OWNER' | translate }}</mat-label>
            <mat-select formControlName="AccountOwner" required>
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
                  noEntriesFoundLabel="{{
                    'COMMON.NO_RESULTS_FOUND' | translate
                  }}"
                  [(ngModel)]="userInformationSearch"
                  [ngModelOptions]="{ standalone: true }"
                >
                  ></ngx-mat-select-search
                >
              </mat-option>
              <mat-option
                class="form-select-option"
                *ngFor="
                  let user of userInformationList
                    | filter : userInformationSearch
                "
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{
              'ACCOUNTS_MENU.ACCOUNT_NAME' | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="AccountName"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'ACCOUNTS_MENU.PHONE' | translate }}</mat-label>
            <input type="text" matInput formControlName="Phone" />
            <mat-error *ngIf="accountForm.get('Phone').hasError('pattern')">
              Please enter a valid phone number.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label>{{ 'ACCOUNTS_MENU.WEBSITE' | translate }}</mat-label>
            <input type="text" matInput formControlName="Website" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  &nbsp;&nbsp;&nbsp;
  <div class="row quick-create-modal-rtl ms-5">
    <div class="col-md-12 d-flex flex-row-reverse">
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn"
        *ngIf="isLoading"
      >
        <mat-spinner diameter="20"></mat-spinner></button
      >&nbsp;&nbsp;&nbsp;
      <button
        mat-flat-button
        color="primary"
        class="flat-info-btn btn-text-size"
        [disabled]="!accountForm.valid"
        (click)="createAccount(accountForm.value)"
        *ngIf="!isLoading"
      >
        {{ 'COMMON.SAVE' | translate }}<mat-icon>save</mat-icon></button
      >&nbsp;&nbsp;&nbsp;&nbsp;
      <button
        mat-stroked-button
        color="primary"
        class="btn-border outline-info-btn btn-text-size"
        (click)="cancelForm()"
      >
        {{ 'COMMON.CANCEL' | translate }}<mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
