import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { HelpersService } from '../../services/helpers.service';
import { CommonSharedService } from '../../services/common-shared.service';
import { LookupsService } from '../../services/lookups.service';
import {
  LookUpElement,
  MeetingParticipantTypeList,
  MeetingParticipantType,
  MeetingParticipant,
} from '../../models/common-data.model';
import { CommonService } from '../../services/common.service';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-meeting-participants',
  templateUrl: './meeting-participants.component.html',
  styleUrls: ['./meeting-participants.component.scss'],
})
export class MeetingParticipantsComponent {
  @ViewChild('template', { static: true }) template: any;
  meetingParticipantsDataSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  meetingParticipantForm: FormGroup;
  isLoading: boolean = false;
  participantsTypeList: LookUpElement[];
  meetingParticipantType: MeetingParticipantType;
  meetingParticipantTypeList: MeetingParticipantTypeList[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fruits: Fruit[] = [];
  meetingParticipants: MeetingParticipant[] = [];
  @Input() isEdit = false;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private commonSharedService: CommonSharedService,
    private lookupsService: LookupsService,
    private commonService: CommonService
  ) {
    this.meetingParticipantsDataSubject = commonSharedService
      .getSubjectOpenMeetingParticipantsModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'Open') {
          this.showModal();
        }
      });

    this.commonSharedService
      .getSubjectMeetingParticipants()
      .pipe(takeUntil(this.destroy))
      .subscribe((meetingParticipants: MeetingParticipant[]) => {
        if (meetingParticipants) {
          let externalEmails = meetingParticipants
            .filter((participant) => participant.participantId == null)
            .map((participant) => participant.participantEmail);
          externalEmails.forEach((email) => {
            let externalEmail: Fruit = {
              name: email,
            };
            this.fruits.push(externalEmail);
          });
          this.meetingParticipants = meetingParticipants.filter(
            (participant) => participant.participantId != null
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.modalRef?.hide();
    this.destroy.next(null);
    this.commonSharedService.setSubjectOpenMeetingParticipantsModal(null);

    this.commonSharedService.setSubjectMeetingParticipants(null);
  }

  ngOnInit(): void {}

  private showModal() {
    this.createAddDealMasterForm();
    this.getParticipantsTypeLookup();
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-lg',
    });
  }

  createAddDealMasterForm() {
    this.meetingParticipantForm = this.fb.group({
      SearchText: [''],
      Type: [''],
    });
  }

  cancelForm() {
    this.modalRef?.hide();
    this.fruits = [];
  }

  getParticipantsTypeLookup() {
    this.lookupsService
      .getMeetingMasterLookup('ParticipantType')
      .subscribe((participantsTypeList: LookUpElement[]) => {
        this.participantsTypeList = participantsTypeList;
        this.meetingParticipantForm
          .get('Type')
          .setValue(participantsTypeList[0].id);
        this.getMeetingParticipantsByType();
      });
  }

  getMeetingParticipantsByType() {
    let participantType = this.participantsTypeList.filter(
      (participantType) =>
        participantType.id == this.meetingParticipantForm.get('Type').value
    )[0]?.name;
    let searchText = this.meetingParticipantForm.get('SearchText').value;

    if (participantType) {
      this.meetingParticipantType = {
        participantType: participantType,
        searchText: searchText,
        isWithoutEmail: false,
      };

      this.commonService
        .getMeetingParticipants(this.meetingParticipantType)
        .pipe(takeUntil(this.destroy))
        .subscribe(
          (meetingParticipantTypeList: MeetingParticipantTypeList[]) => {
            this.meetingParticipantTypeList = meetingParticipantTypeList;
            this.meetingParticipantTypeList.map((participantType) => {
              if (this.meetingParticipants.length != 0) {
                var result = this.meetingParticipants.filter(
                  (participant) =>
                    participant.participantId == participantType.id &&
                    participant.participantEmail == participantType.email
                );
                if (result.length != 0) {
                  participantType.checked = true;
                } else {
                  participantType.checked = false;
                }
              }
            });
          }
        );
    }
  }

  private validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (this.validateEmail(value)) {
        if (this.checkEmailAlreadyExist(value)) {
          this.helpersService.showErrorToast('Email already exist');
        } else {
          this.fruits.push({ name: value });
        }
      } else {
        this.helpersService.showErrorToast('Invalid email');
      }
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  checkEmailAlreadyExist(email) {
    let index1 = this.fruits.map((a) => a.name).indexOf(email);
    console.log('index1', index1);

    let index2 = this.meetingParticipants
      .map((participant) => participant.participantEmail)
      .indexOf(email);
    if (index1 > -1 || index2 > -1) {
      return true;
    } else {
      return false;
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  edit(fruit: Fruit, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(fruit);
      return;
    }

    // Edit existing fruit
    const index = this.fruits.indexOf(fruit);
    if (index >= 0) {
      this.fruits[index].name = value;
    }
  }

  selectParticipants(
    checked: boolean,
    selectedParticipant: MeetingParticipantTypeList
  ) {
    var participantTypeId = this.meetingParticipantForm.get('Type').value;

    if (checked) {
      let meetingParticipants: MeetingParticipant = {
        participantId: selectedParticipant?.id,
        participantEmail: selectedParticipant?.email,
        participantTypeId: participantTypeId,
      };
      this.meetingParticipants.push(meetingParticipants);
    } else {
      this.meetingParticipants = this.meetingParticipants.filter(
        (participant) =>
          participant.participantId !== selectedParticipant?.id &&
          participant.participantEmail !== selectedParticipant?.email
      );
    }

    console.log('this.meetingParticipants', this.meetingParticipants);
  }

  setMeetingParticipants() {
    if (this.fruits.length != 0) {
      this.fruits.forEach((element) => {
        let meetingParticipants: MeetingParticipant = {
          participantId: null,
          participantEmail: element?.name,
          participantTypeId: null,
        };

        let index = this.meetingParticipants
          .map((participant) => participant.participantEmail)
          .indexOf(element?.name);
        if (index < 0) {
          this.meetingParticipants.push(meetingParticipants);
        }
      });
    }

    this.commonSharedService.setSubjectMeetingParticipants(
      this.meetingParticipants
    );

    this.cancelForm();
  }

  clearSearch() {
    let searchText = this.meetingParticipantForm.get('SearchText').value;
    if(searchText) {
      this.meetingParticipantForm.get('SearchText').setValue('');
      this.getMeetingParticipantsByType();
    }
  }

  clearAllSelected() {
    if(this.fruits.length != 0 || this.meetingParticipants.length != 0) {
      this.fruits = [];
      this.meetingParticipants = [];
      this.getMeetingParticipantsByType();
    }
  }

  getSelectedParticipantType(id: string) {
    let participantType = this.participantsTypeList?.filter(type => type.id == id)[0]?.name;
    return participantType;
  }
}
