import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { QuickCampaign } from '../../../models/quick-create.model'
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { QuickCreateService } from 'src/app/shared/services/quick-create.service';
import { QuickCreateSharedService } from 'src/app/shared/services/quick-create-shared.service';

@Component({
  selector: 'app-quick-create-campaign-modal',
  templateUrl: './quick-create-campaign-modal.component.html',
  styleUrls: ['./quick-create-campaign-modal.component.scss']
})
export class QuickCreateCampaignModalComponent {

  @ViewChild('template', { static: true }) template: any;
  campaignCreateSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  campaignForm: FormGroup;
  isLoading: boolean = false;
  campaignStatusList: LookUpElement[] = [];
  campaignTypeList: LookUpElement[] = [];
  campaignStatusSearch: string;
  campaignTypeSearch: string;
  quickCampaignReq: QuickCampaign;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translateService :TranslateService,
    private helpersService: HelpersService,
    private quickCreateSharedService: QuickCreateSharedService,
    private lookupsService: LookupsService,
    private quickCreateService: QuickCreateService
  ){
    this.campaignCreateSubject = quickCreateSharedService
    .getSubjectQuickCreateCampaignOpenModal()
    .pipe(takeUntil(this.destroy))
    .subscribe((sortByOpen: any) => {
      if(sortByOpen!= null && sortByOpen == 'Open') {
        this.showModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.quickCreateSharedService.setSubjectQuickCreateCampaignOpenModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

  ngOnInit(): void {
    this.createCampaignForm();
    this.getLookupList();
  }

  getLookupList() {
    this.getCamapignStatusList();
    this.getCamapignTypeList();
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      // class: 'modal-dialog-centered modal-lg',
      class: 'modal-dialog-right modal-lg'
    });
  }

  createCampaignForm() {
    this.campaignForm = this.fb.group({
      CampaignTypeId: [null],
      CampaignName: [''],
      CampaignStatusId: [null],
      StartDate: [null],
      EndDate: [null],
      ExpectedRevenue: [0]
    });
  }

  getCurrentDate() {
    return new Date();
  }

  cancelForm(){
    this.modalRef.hide();
    this.campaignForm.reset();
  }

  getCamapignStatusList() {
    this.lookupsService
      .getCampaignLookup('CampaignStatus')
      .subscribe((campaignStatusList: LookUpElement[]) => {
        this.campaignStatusList = campaignStatusList;
      });
  }

  getCamapignTypeList() {
    this.lookupsService
      .getCampaignLookup('CampaignType')
      .subscribe((campaignTypeList: LookUpElement[]) => {
        this.campaignTypeList = campaignTypeList;
      });
  }

  createCampaign(formData: any){
    this.setReqBody(formData);
    this.saveCampaignInDB();
  }

  setReqBody(formData: any) {
    this.quickCampaignReq = {
      campaignTypeId: formData.CampaignTypeId,
      campaignName: formData.CampaignName,
      campaignStatusId: formData.CampaignStatusId,
      startDate: formData.StartDate,
      endDate: formData.EndDate,
      expectedRevenue: formData.ExpectedRevenue ?? 0
    }
  }

  saveCampaignInDB() {
    this.isLoading = true;
    this.quickCreateService
    .quickCreateCampaign(this.quickCampaignReq)
    .subscribe((res: any) => {
      if(res) {
        this.cancelForm();
        this.helpersService.showSuccessToast("Created Successfully");
        this.quickCreateSharedService.setSubjectQuickCreateCampaignOpenModal(null);
        this.quickCreateSharedService.setSubjectChooseCampaignListTableChanged(true);
        this.isLoading = false;
      }
    }, () => {
      this.isLoading = false;
    });
  }

}
