import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { FileUploadData } from "../models/file-upload.model";
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private apiFileUrl ="api/File"
  constructor(private baseDataService : BaseDataService) {

   }

   uploadFile(formData:FormData) :Observable<FileUploadData>{
    return this.baseDataService.makePostCallImageUpload(this.apiFileUrl+"/fileUpload",formData)
   }


  }
